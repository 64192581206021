/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

// global google

import React, { useEffect } from 'react';
import { FormInput, FormDateTimePicker, FormTextArea, FormNumericTextBox, FormComboBox } from '../../App/KendoFormElements/FormComponents';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { Col, Container, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
import Navbar from 'react-bootstrap/Navbar';
import { Label } from '@progress/kendo-react-labels';
import Nav from 'react-bootstrap/Nav';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Chart } from 'react-google-charts';
import { Accordion, AccordionHeading, AccordionContent } from '../../Helpers/Accordion';

import { optionsDisruptions } from '../../../Assets/json/formOptions';

import { ApiMissionListByState, ApiMissionByIdHelper, ApiLegsById, ApiUpdateLegHistoryByIdForm, ApiLiftRequestById, ApiChangeMissionState, ApiNewDisruption, ApiAssetById, ApiChangeLiftRequestState } from '../../Helpers/Apis';
import { stripTimeZone, forceDateStringToUTCISOFormat } from '../../Helpers/DateTimeConversions';

import { toast } from 'react-toastify';

import { GoogleMap, useJsApiLoader, OverlayView, Polyline } from '@react-google-maps/api';
import { getDistance } from 'geolib';
import Marker from '../../Maps/Marker';
import { accessValidator } from '../../Helpers/RolesPermissions';

//uncomment next line to load with demo-able test data
// import missionStatusTest from "../../Helpers/missionStatusTest.json"
// import solverResponseTest from '../../Helpers/SolverResponseTest.json';
// import MissionStatusLRStatusTestData from '../../Helpers/MissionStatusLRStatusTestData.json';
// import MissionStatusLRStatusTestData from '../../Helpers/MissionStatusLRStatusTestData2.json';

const TransitionBarTitle = (props) => {
	return (
		<span key={props.i} className={'leg-info-header'} data-leg-id={props.id}>
			<span className={'leg-color'} style={{ backgroundColor: props.legColorsArray[props.i] }}></span>

			{props.locked ? (
				<span className={'leg-header-item'}>
					<i className="fa-solid fa-lock"></i>
				</span>
			) : (
				<span className={'leg-header-item'}>
					<i className="fa-solid fa-lock fa-blank"></i>
				</span>
			)}

			<span className={'leg-header-item leg-counter'}>{`Leg ${props.legVal}`}</span>

			<span className={'leg-header-item leg-airport-range'}>
				<span title={props.airportNameDeparture}>{props.locationFrom}</span>
				<i className={'padding-left-5 padding-right-5 fas fa-plane'}></i>
				<span title={props.airportNameArrival}>{props.locationTo}</span>
			</span>

			<span className={'leg-header-item leg-date-range'}>
				{props.startTime} <i className={'far fa-arrow-right'}></i> {props.endTime}
			</span>

			<span className={'leg-header-item config-id'}>Config: {props.configurationId}</span>

			<span className={'leg-header-item leg-locked-toggle padding-left-30'}>
				{/* disable this switch if cargo is empty. But if last last leg, then always NOT disabled. */}
				<Switch onChange={props.buttonClickHandler.bind(props, props.locked, props.i)} defaultChecked={false} onLabel={'Yes'} offLabel={'No'} checked={props.locked} size={'large'} trackRounded={'large'} thumbRounded={'large'} disabled={!props.emptyCargo && !props.lastLeg} />
				<Label className={'padding-left-15 leg-locked-toggle-lbl'} id={'switchLabel'} style={{ display: 'inline-block', left: '150px' }}>
					Leg Locked
				</Label>
			</span>

			{props.status === 'Completed' ? (
				<span className={'leg-status'}>
					<span className={'leg-status-lbl'}>{props.status}</span>
					<i className={'fas fa-circle-check'}></i>
				</span>
			) : (
				<span className={'leg-status'}>
					<span className={'leg-status-lbl'}>{props.status}</span>
					<i className={'fas fa-circle-minus'}></i>
				</span>
			)}
		</span>
	);
};

const MissionStatus = (props) => {
	const params = useParams();
	const [missionsList, setMissionsList] = React.useState([]);

	const missionId = params.missionId ? params.missionId : '';
	const [missionName, setMissionName] = React.useState('loading');

	const [windowView, setWindowView] = React.useState('minimize'); // maximize, minimize toggle
	const [missionList, setMissionList] = React.useState(true);
	const [apiMissionData, setApiMissionData] = React.useState(true);
	const [missionTailNumber, setMissionTailNumber] = React.useState('');
	const [loaded, setLoaded] = React.useState(false);
	const [missionLoaded, setMissionLoaded] = React.useState(false);
	const [legReload, setLegReload] = React.useState(1);

	// Map
	const [latitudeAvg, setLatitudeAvg] = React.useState(32.8481);
	const [longitudeAvg, setLongitudeAvg] = React.useState(-96.8512);
	const [visualData, setVisualData] = React.useState([]); // Data for Map Markers, Polyline, and Gantt Chart

	// Gantt Chart: Start
	const [legData, setLegData] = React.useState([]);
	const [statsData, setStatsData] = React.useState([]);

	const [arrivalFields, setArrivalFields] = React.useState(['']);
	const [departureFields, setDepartureFields] = React.useState(['']);
	const [palletPickupFieldsFirst, setPalletPickupFieldsFirst] = React.useState([['']]);
	const [palletPickupFieldsLast, setPalletPickupFieldsLast] = React.useState([['']]);
	const [palletDropoffFieldsFirst, setPalletDropoffFieldsFirst] = React.useState([['']]);
	const [palletDropoffFieldsLast, setPalletDropoffFieldsLast] = React.useState([['']]);
	const [paxPickupFieldsFirst, setPaxPickupFieldsFirst] = React.useState([['']]);
	const [paxPickupFieldsLast, setPaxPickupFieldsLast] = React.useState([['']]);
	const [paxDropoffFieldsFirst, setPaxDropoffFieldsFirst] = React.useState([['']]);
	const [paxDropoffFieldsLast, setPaxDropoffFieldsLast] = React.useState([['']]);
	const [paxDropoffFieldsCount, setPaxDropoffFieldsCount] = React.useState([['']]);
	const [paxPickupFieldsCount, setPaxPickupFieldsCount] = React.useState([['']]);

	const [paxDropoffNotes, setPaxDropoffNotes] = React.useState([['']]);
	const [paxPickupNotes, setPaxPickupNotes] = React.useState([['']]);
	const [palletPickupNotes, setPalletPickupNotes] = React.useState([['']]);
	const [palletDropoffNotes, setPalletDropoffNotes] = React.useState([['']]);

	const [statsExpanded, setStatsExpanded] = React.useState(false);
	const [statsView, setStatsView] = React.useState(true);
	const [ganttView, setGanttView] = React.useState(false);
	const [mapView, setMapView] = React.useState(false);
	const [expanded, setExpanded] = React.useState(['1']);
	const [missionDisruptionType, setMissionDisruptionType] = React.useState('');
	const [missionDisruptionLegType, setMissionDisruptionLegType] = React.useState('');
	const [selectedLegId, setSelectedLegId] = React.useState('');

	const [libraries] = React.useState(['geometry', 'drawing']);

	const [showDisruptionManagement, setShowDisruptionManagement] = React.useState(false);

	const accessMissionEdit = ['nalo'];

	// eslint-disable-next-line
	const { isGoogleLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
		libraries
	});

	const createdBy = {
		id: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.id ? props.keycloak.profile.id : null,
		firstName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.firstName ? props.keycloak.profile.firstName : null,
		lastName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.lastName ? props.keycloak.profile.lastName : null,
		email: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.email ? props.keycloak.profile.email : null,
		phoneNumber: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.phoneNumber ? props.keycloak.profile.phoneNumber : null,
		rank: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.rank ? props.keycloak.profile.rank : null,
		dutyTitle: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.dutyTitle ? props.keycloak.profile.dutyTitle : null,
		unit: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.unit ? props.keycloak.profile.unit : null,
		branchOfService: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.branchOfService ? props.keycloak.profile.branchOfService : null
	};

	// Colors Used for Legs List and Map Polylines
	let legColorsArray = ['#5cd14c', '#3f0545', '#9826ca', '#b8055d', '#edb8c0', '#733a9e', '#09c6f9', '#3d2aa6', '#a5b0f0', '#fb5e6c', '#08c526', '#9b7f31', '#e20eb4', '#45e275', '#4d62f7', '#c53079', '#38718d', '#d222c7', '#4a9b65', '#866823', '#0097a1', '#858d8b', '#e36d90', '#3d1993', '#758adf', '#8d2af6', '#b0518b', '#3f68e5', '#cfac10', '#14e4c3'];

	// Map Options
	const mapOptions = {
		// Map Styling
		style: {
			width: '100%',
			height: '100%'
		},

		// New Zealand/Australia: Default Map Center Point on Load
		center: {
			lat: latitudeAvg,
			lng: longitudeAvg
		},

		// Default Zoom Level
		zoom: 3,

		options: {
			maxZoom: 18,
			minZoom: 3
		}
	};

	// Define refs for Polygon instance and listeners

	const handleVisualData = () => {
		const p = [];
		let lats = [];
		let lons = [];

		legData.map((rec, i) => {
			if (rec.transitionBar) {
				return p.push({
					arrivalAirport: rec.arrivalAirport,
					arrivalAirportLat: rec.arrivalAirportLat,
					arrivalAirportLong: rec.arrivalAirportLong,
					arrivalAirportName: rec.arrivalAirportName,
					departureAirport: rec.departureAirport,
					departureAirportLat: rec.departureAirportLat,
					departureAirportLong: rec.departureAirportLong,
					departureAirportName: rec.departureAirportName,
					end: rec.end,
					id: rec.id,
					start: rec.start,
					title: rec.title,

					name: rec.title,
					lat: rec.departureAirportLat,
					lng: rec.departureAirportLong,
					color: legColorsArray[i],

					departureLat: rec.departureAirportLat,
					departureLng: rec.departureAirportLong,
					arrivalLat: rec.arrivalAirportLat,
					arrivalLng: rec.arrivalAirportLong,
					geodesic: true,
					strokeColor: legColorsArray[i],
					strokeOpacity: 0.7,
					strokeWeight: 3,
					fillColor: legColorsArray[i],
					fillOpacity: 0.35,
					clickable: true,
					draggable: false,
					editable: false,
					visible: true,
					radius: 30000,
					paths: [],
					zIndex: 3
				});
			}
			return null;
		});

		// Defaults: US Lat/Long Center
		const getAvgLats = lats.length > 0 ? lats.reduce((a, b) => a + b) / lats.length : 37.0902;
		const getAvgLons = lons.length > 0 ? lons.reduce((a, b) => a + b) / lons.length : -95.7129;

		setLatitudeAvg(getAvgLats);
		setLongitudeAvg(getAvgLons);

		setVisualData(p);
	};

	//******************* Map: End

	const handleStatsToggle = () => {
		setStatsView(!statsView);
	};

	const handleGanttToggle = () => {
		setGanttView(!ganttView);
	};

	const handleMapToggle = () => {
		setMapView(!mapView);
	};

	const navigate = useNavigate();

	const missionDataFetch = () => {
		ApiMissionByIdHelper(missionId).then((res) => {
			setMissionName(res.missionName);
			setApiMissionData(res);
			setMissionLoaded(true);
		});
	};

	const checkCargoCompleted = (legCargoArray) => {
		var cargoCompletedBool = true;
		if (legCargoArray !== null) {
			legCargoArray.map((cargo, i) => {
				if (cargo !== undefined) {
					if (cargo.signOff === undefined || cargo.signOff === null || cargo.signOff.firstName === null || cargo.signOff.firstName === undefined || cargo.signOff.lastName === null || cargo.signOff.lastName === undefined) {
						cargoCompletedBool = false;
					}
				}
				return null;
			});
			return cargoCompletedBool;
		}
		return null;
	};

	const createTransitionObject = (obj, i, titleString, legStatus, originalPalletOnlyLegArray, originalPaxOnlyLegArray, originalPalletLastLegArray, originalPaxLastLegArray, originaPaxPickupArray, originalPalletPickupArray, transitionBarPaxLastLegArray, transitionBarPalletLastLegArray, transitionBarPalletDropoffArray, transitionBarPaxDropoffArray, originalOnBoardArray, transitionBarPaxOnBoardArray, transitionBarPalletOnBoardArray, paxPickupArray, palletPickupArray, lastLeg) => {
		var transitionObject = {
			departure: obj[i].history[0].departure !== null || obj[i].history[0].departure !== undefined ? obj[i].history[0].departure : null,
			departureAirport: obj[i].history[0].departure !== null ? obj[i].history[0].departure.airport.icao : null,
			departureAirportLat: obj[i].history[0].departure !== null ? obj[i].history[0].departure.airport.latitude : null,
			departureAirportLong: obj[i].history[0].departure !== null ? obj[i].history[0].departure.airport.longitude : null,
			departureTime: obj[i].history[0].departure !== null ? obj[i].history[0].departure.planned : null,
			departureAirportName: obj[i].history[0].departure !== null ? obj[i].history[0].departure.airport.name : null,

			arrival: obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined ? obj[i].history[0].arrival : null,
			arrivalAirport: obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined ? obj[i].history[0].arrival.airport.icao : null,
			arrivalAirportLat: obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined ? obj[i].history[0].arrival.airport.latitude : null,
			arrivalAirportLong: obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined ? obj[i].history[0].arrival.airport.longitude : null,
			arrivalAirportName: obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined ? obj[i].history[0].arrival.airport.name : null,
			arrivalTime: obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined ? obj[i].history[0].arrival.planned : null,

			originalCargoPaxPickup: originaPaxPickupArray,
			originalCargoPalletPickup: originalPalletPickupArray,
			originalOnBoard: originalOnBoardArray,
			originalCargoPalletOnlyLeg: originalPalletOnlyLegArray,
			originalCargoPaxOnlyLeg: originalPaxOnlyLegArray,
			originalCargoPalletLastLeg: originalPalletLastLegArray,
			originalCargoPaxLastLeg: originalPaxLastLegArray,

			cargoPalletsDropoff: transitionBarPalletDropoffArray,
			cargoPaxDropoff: transitionBarPaxDropoffArray,
			cargoPaxOnboard: transitionBarPaxOnBoardArray,
			cargoPaxLastLeg: transitionBarPaxLastLegArray,
			cargoPalletLastLeg: transitionBarPalletLastLegArray,
			cargoPalletsOnboard: transitionBarPalletOnBoardArray,
			cargoPaxPickup: paxPickupArray,
			cargoPalletsPickup: palletPickupArray,

			start: obj[i].history[0].departure !== null ? obj[i].history[0].departure.planned : null,
			end: obj[i].history[0].arrival !== null ? obj[i].history[0].arrival.planned : null,
			id: obj[i].id,
			satisfiesLiftRequests: obj[i].history[0].satisfiesLiftRequests,

			missionId: missionId,
			state: obj[i].history[0].state !== null && obj[i].history[0].state !== undefined ? obj[i].history[0].state : null,
			createdAt: obj[i].history[0].createdAt !== null || obj[i].history[0].createdAt !== undefined ? obj[i].history[0].createdAt : null,
			aircraftConfigurationId: obj[i].history[0].aircraftConfigurationId !== null || obj[i].history[0].aircraftConfigurationId !== undefined ? obj[i].history[0].aircraftConfigurationId : null,
			reconfigurationEvent: obj[i].history[0].reconfigurationEvent !== null || obj[i].history[0].reconfigurationEvent !== undefined ? obj[i].history[0].reconfigurationEvent : null,
			createdBy: obj[i].history[0].createdBy !== null || obj[i].history[0].createdBy !== undefined ? obj[i].history[0].createdBy : null,
			assetId: obj[i].history[0].assetId !== null || obj[i].history[0].assetId !== undefined ? obj[i].history[0].assetId : null,

			title: titleString,
			transitionBar: true,
			legVal: i + 1,
			status: legStatus
		};

		if (lastLeg) {
			transitionObject.lastLeg = true;
		}
		return transitionObject;
	};

	const createLegObject = (obj, i, missionId, paxPickupArray, palletPickupArray, totalPalletPickup, totalPaxPickup, cargoPalletCompletedPickup, cargoPaxCompletedPickup, palletDropoffArray, paxDropoffArray, totalPalletDropoff, totalPaxDropoff, cargoPaxCompletedDropoff, cargoPalletCompletedDropoff, legStatus, firstLeg, lastLeg) => {
		var legObject = {
			id: obj[i].id,
			missionId: missionId,

			arrivalAirport: obj[i - 1] !== undefined && obj[i - 1].history[0].arrival !== null ? obj[i - 1].history[0].arrival.airport.icao : null,
			arrival: obj[i - 1] !== undefined && obj[i - 1].history[0].arrival !== null && obj[i - 1].history[0].arrival !== undefined ? obj[i - 1].history[0].arrival : null,
			arrivalAirportName: obj[i - 1] !== undefined && obj[i - 1].history[0].arrival !== null && obj[i - 1].history[0].arrival !== undefined ? obj[i - 1].history[0].arrival.airport.name : null,
			arrivalAirportLat: obj[i - 1] !== undefined && obj[i - 1].history[0].arrival !== null && obj[i - 1].history[0].arrival !== undefined ? obj[i - 1].history[0].arrival.airport.latitude : null,
			arrivalAirportLong: obj[i - 1] !== undefined && obj[i - 1].history[0].arrival !== null && obj[i - 1].history[0].arrival !== undefined ? obj[i - 1].history[0].arrival.airport.longitude : null,
			arrivalTime: obj[i - 1] !== undefined && obj[i - 1].history[0].arrival !== null ? obj[i - 1].history[0].arrival.planned : null,

			departureAirport: obj[i].history[0].departure !== null ? obj[i].history[0].departure.airport.icao : null,
			departure: obj[i].history[0].departure !== null || obj[i].history[0].departure !== undefined ? obj[i].history[0].departure : null,
			departureAirportName: obj[i].history[0].departure !== null ? obj[i].history[0].departure.airport.name : null,
			departureAirportLat: obj[i].history[0].departure !== null ? obj[i].history[0].departure.airport.latitude : null,
			departureAirportLong: obj[i].history[0].departure !== null ? obj[i].history[0].departure.airport.longitude : null,
			departureTime: obj[i].history[0].departure !== null ? obj[i].history[0].departure.planned : null,

			satisfiesLiftRequests: obj[i].history[0].satisfiesLiftRequests,
			createdAt: obj[i].history[0].createdAt !== null || obj[i].history[0].createdAt !== undefined ? obj[i].history[0].createdAt : null,
			aircraftConfigurationId: obj[i].history[0].aircraftConfigurationId !== null || obj[i].history[0].aircraftConfigurationId !== undefined ? obj[i].history[0].aircraftConfigurationId : null,
			reconfigurationEvent: obj[i].history[0].reconfigurationEvent !== null || obj[i].history[0].reconfigurationEvent !== undefined ? obj[i].history[0].reconfigurationEvent : null,
			createdBy: obj[i].history[0].createdBy !== null || obj[i].history[0].createdBy !== undefined ? obj[i].history[0].createdBy : null,
			state: obj[i].history[0].state !== null || obj[i].history[0].state !== undefined ? obj[i].history[0].state : null,
			assetId: obj[i].history[0].assetId !== null || obj[i].history[0].assetId !== undefined ? obj[i].history[0].assetId : null,

			cargoPaxPickup: paxPickupArray,
			cargoPalletsPickup: palletPickupArray,
			totalPalletPickup: totalPalletPickup,
			totalPaxPickup: totalPaxPickup,
			palletCompletedPickup: cargoPalletCompletedPickup,
			paxCompletedPickup: cargoPaxCompletedPickup,
			palletCompletedPickupCheck: checkCargoCompleted(palletPickupArray),
			paxCompletedPickupCheck: paxPickupArray !== null && paxPickupArray.length === 0 && obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined && obj[i].history[0].arrival.actual === null ? true : false,

			cargoPalletsDropoff: palletDropoffArray,
			cargoPaxDropoff: paxDropoffArray,
			totalPalletDropoff: totalPalletDropoff,
			totalPaxDropoff: totalPaxDropoff,
			paxCompletedDropoff: cargoPaxCompletedDropoff,
			palletCompletedDropoff: cargoPalletCompletedDropoff,
			palletCompletedDropoffCheck: checkCargoCompleted(palletDropoffArray),
			paxCompletedDropoffCheck: false,

			title: obj[i].history[0].departure !== null ? obj[i].history[0].departure.airport.icao : null,
			status: legStatus,
			transitionBar: false,
			firstLeg: firstLeg,
			lastLeg: lastLeg
		};

		if (lastLeg) {
			legObject.departureAirport = null;
			legObject.departureTime = null;
			legObject.departureAirportName = null;
			legObject.departureAirportLat = null;
			legObject.departureAirportLong = null;
			legObject.departure = null;

			legObject.arrivalAirport = obj[i] !== undefined && obj[i].history[0].arrival !== null ? obj[i].history[0].arrival.airport.icao : null;
			legObject.arrival = obj[i] !== undefined && obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined ? obj[i].history[0].arrival : null;
			legObject.arrivalAirportName = obj[i] !== undefined && obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined ? obj[i].history[0].arrival.airport.name : null;
			legObject.arrivalAirportLat = obj[i] !== undefined && obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined ? obj[i].history[0].arrival.airport.latitude : null;
			legObject.arrivalAirportLong = obj[i] !== undefined && obj[i].history[0].arrival !== null && obj[i].history[0].arrival !== undefined ? obj[i].history[0].arrival.airport.longitude : null;
			legObject.arrivalTime = obj[i] !== undefined && obj[i].history[0].arrival !== null ? obj[i].history[0].arrival.planned : null;

			legObject.title = obj[i].history[0].arrival !== null ? obj[i].history[0].arrival.airport.icao : null;
		}
		return legObject;
	};

	const legsDataFetch = () => {
		if (missionId) {
			//build mission data object
			ApiLegsById(missionId).then((res) => {
				//uncomment next line to load with demo-able test data
				// res = missionStatusTest
				// res = solverResponseTest
				// res = MissionStatusLRStatusTestData;

				//check if mission has already been processed by checking if any liftRequest_status's contain custom states
				var processed = false;
				// eslint-disable-next-line
				res.map((leg) => {
					if (leg.history[0].cargoes !== null) {
						// eslint-disable-next-line
						leg.history[0].cargoes.map((cargo) => {
							if (cargo.liftrequest_status.includes('ui_')) {
								processed = true;
							}
						});
					}
				});

				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log('legs by mission Id response: ', res);
				}

				const data = JSON.stringify(res);
				const obj = JSON.parse(data);
				var totalFlightTime = 0;
				var totalDistance = 0;
				var totalDistanceInMiles = 0;

				var palletDropoffArray = [];
				var palletPickupArray = [];
				var paxDropoffArray = [];
				var paxPickupArray = [];

				var palletDropoffArrayLastLegDep = [];
				var paxDropoffArrayLastLegDep = [];

				var palletDropoffArrayLastLegArr = [];
				var paxDropoffArrayLastLegArr = [];

				var transitionBarPalletDropoffArray = [];
				var transitionBarPaxDropoffArray = [];
				var transitionBarPaxOnBoardArray = [];
				var transitionBarPalletOnBoardArray = [];
				var transitionBarPaxLastLegArray = [];
				var transitionBarPalletLastLegArray = [];

				var originaPaxPickupArray = [];
				var originalPalletPickupArray = [];
				var originalOnBoardArray = [];
				var originalPaxLastLegArray = [];
				var originalPalletLastLegArray = [];
				var originalPaxOnlyLegArray = [];
				var originalPalletOnlyLegArray = [];

				var legsCompleted = 0;
				var legsRemaining = 0;
				var liftsCompleted = 0;
				var liftsRemaining = 0;
				var daysCompleted = 0;
				var daysRemaining = 0;
				var totalPalletDropoff = 0;
				var totalPalletPickup = 0;
				var totalPaxPickup = 0;
				var totalPaxDropoff = 0;
				var totalPaxDropoffLastLegArr = 0;
				var totalPaxDropoffLastLegDep = 0;
				var totalPalletDropoffLastLegArr = 0;
				var totalPalletDropoffLastLegDep = 0;
				var today = new Date();
				var earliestDate = '';
				var latestDate = '';
				var formattedPriPOCString = '';
				var formattedAltPOCString = '';
				var formattedVipTravelerString = '';
				var formattedSeniorTravelerString = null;
				var cargoPalletCompletedDropoff = true;
				var cargoPaxCompletedDropoff = true;
				var cargoPalletCompletedPickup = true;
				var cargoPaxCompletedPickup = true;
				var legStatus = '';
				const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

				const statsObj = [];
				const jsonArray = [];
				var i = 0;

				setArrivalFields([['']]);
				setDepartureFields([['']]);
				setPalletPickupFieldsFirst([['']]);
				setPalletPickupFieldsLast([['']]);
				setPalletDropoffFieldsFirst([['']]);
				setPalletDropoffFieldsLast([['']]);
				setPaxPickupFieldsFirst([['']]);
				setPaxPickupFieldsLast([['']]);
				setPaxDropoffFieldsFirst([['']]);
				setPaxDropoffFieldsLast([['']]);
				setPaxDropoffFieldsCount([['']]);
				setPaxPickupFieldsCount([['']]);

				while (i < res.length) {
					cargoPalletCompletedPickup = true;
					cargoPaxCompletedPickup = true;
					cargoPalletCompletedDropoff = true;
					cargoPaxCompletedDropoff = true;
					totalPalletDropoff = 0;
					totalPalletPickup = 0;
					totalPaxPickup = 0;
					totalPaxDropoff = 0;
					totalPaxDropoffLastLegArr = 0;
					totalPaxDropoffLastLegDep = 0;
					totalPalletDropoffLastLegArr = 0;
					totalPalletDropoffLastLegDep = 0;
					palletDropoffArray = [];
					palletPickupArray = [];
					paxDropoffArray = [];
					paxPickupArray = [];

					palletDropoffArrayLastLegDep = [];
					paxDropoffArrayLastLegDep = [];

					palletDropoffArrayLastLegArr = [];
					paxDropoffArrayLastLegArr = [];

					legStatus = 'Not Started';

					//arrays used to build out our transition bar objects for easier submission.
					transitionBarPalletDropoffArray = [];
					transitionBarPaxDropoffArray = [];
					transitionBarPaxOnBoardArray = [];
					transitionBarPalletOnBoardArray = [];
					transitionBarPaxLastLegArray = [];
					transitionBarPalletLastLegArray = [];

					originaPaxPickupArray = [];
					originalPalletPickupArray = [];
					originalOnBoardArray = [];
					originalPaxLastLegArray = [];
					originalPalletLastLegArray = [];
					originalPaxOnlyLegArray = [];
					originalPalletOnlyLegArray = [];

					// totalFlightTime is the summation of all flight times (arrival-departure in hours)
					if (obj[i].history[0].departure !== null && obj[i].history[0].arrival !== null) {
						totalFlightTime += (new Date(obj[i].history[0].arrival.planned).getTime() - new Date(obj[i].history[0].departure.planned).getTime()) / 1000 / (60 * 60);
						if (i === 0) {
							earliestDate = new Date(stripTimeZone(obj[i].history[0].departure.planned));
							if (obj[i].history[0].assetId !== null) {
								ApiAssetById(obj[i].history[0].assetId).then((res) => {
									setMissionTailNumber(res.tailNumber);
								});
							} else {
								setMissionTailNumber('');
							}
						}
						if (i === res.length - 1) {
							latestDate = new Date(stripTimeZone(obj[i].history[0].arrival.planned));
						}

						// totalDistance is the summation of all airport coordinate distances (arrival and departure in miles)
						totalDistance += getDistance(
							{
								latitude: obj[i].history[0].departure.airport.latitude,
								longitude: obj[i].history[0].departure.airport.longitude
							},
							{
								latitude: obj[i].history[0].arrival.airport.latitude,
								longitude: obj[i].history[0].arrival.airport.longitude
							}
						);

						var from = obj[i].history[0].departure.airport.icao;
						var to = obj[i].history[0].arrival.airport.icao;
						var titleString = from + ' to ' + to;
					}

					legStatus = checkLegStatus(null, obj[i].history[0]);
					if (legStatus === 'Completed') {
						legsCompleted += 1;
					} else {
						legsRemaining += 1;
					}

					//calculate completed vs remaining lifts
					if (obj[i].history[0].cargoes !== null && obj[i].history[0].cargoes !== undefined) {
						formattedPriPOCString = '';
						formattedAltPOCString = '';
						formattedVipTravelerString = '';
						formattedSeniorTravelerString = null;
						cargoPalletCompletedPickup = true;
						cargoPaxCompletedPickup = true;
						cargoPalletCompletedDropoff = true;
						cargoPaxCompletedDropoff = true;

						if (obj[i].history[0].cargoes !== null) {
							// eslint-disable-next-line no-loop-func
							obj[i].history[0].cargoes.map((cargo, j) => {
								// Pick up: picked up at departure airport
								// Pick up (only leg): picked up at departure airport and dropped off at arrival airport
								// On board: picked up at previous departure airport and drop off after arrival airport (will stay on board this leg and is not affected by this leg)
								// Last leg: on board at departure airport but dropped off at arrival airport

								//check for incorrect last leg cargo statuses
								if (cargo.liftrequest_status.includes('lastLegDeparture') || cargo.liftrequest_status.includes('lastLegArrival')) {
									let cargoLRStatus = cargo.liftrequest_status;
									//if this isnt a moved item, then fix it.
									let movedAheadCargo = false;
									if (obj[i - 1] !== undefined && obj[i - 1].history[0].cargoes !== null) {
										// eslint-disable-next-line
										obj[i - 1].history[0].cargoes.map((cargo2) => {
											if ((cargo2.liftrequest_status === cargoLRStatus || cargo2.liftrequest_status.includes('only leg') || cargo2.liftrequest_status.includes('last_leg')) && cargo2.count === cargo.count && cargo2.type === cargo.type) {
												movedAheadCargo = true;
											}
										});
									}

									if (!movedAheadCargo) {
										if (i + 1 !== obj.length - 1) {
											if (cargo.liftrequest_status.includes('dropoff')) {
												cargo.liftrequest_status = 'ui_dropoff';
											}
											if (cargo.liftrequest_status.includes('pickup')) {
												cargo.liftrequest_status = 'ui_pickup';
											}
										}
									}
								}

								//if status contains only leg or last leg. (and hasn't been processed into new object structure yet) dropoff that cargo obj at obj[i + 1]
								if (cargo.liftrequest_status !== null && (cargo.liftrequest_status.includes('only leg') || cargo.liftrequest_status.includes('last_leg') || cargo.liftrequest_status.includes('on_board'))) {
									var tempCargo = {};
									var tempCargoPickup = [];
									var duplicate = false;
									tempCargo = JSON.parse(JSON.stringify(cargo));
									if (cargo.liftrequest_status.includes('only leg') || cargo.liftrequest_status.includes('last_leg')) {
										tempCargo.liftrequest_status = 'ui_dropoff';
									} else if (cargo.liftrequest_status.includes('on_board')) {
										tempCargo.liftrequest_status = 'ui_on_board';
									}

									//if only_leg/last_leg cargo status and i == last leg, then add dropoff with liftrequest status _lastlegArr
									if ((cargo.liftrequest_status.includes('only leg') || cargo.liftrequest_status.includes('last_leg')) && i === res.length - 1) {
										tempCargo.liftrequest_status = 'ui_dropoff_lastLegArrival';
										// eslint-disable-next-line
										obj[i].history[0].cargoes.map((cargo2) => {
											if (cargo2.liftrequest_id === tempCargo.liftrequest_id && cargo2.liftrequest_status === 'ui_dropoff_lastLegArrival' && cargo2.count === tempCargo.count && cargo2.type === tempCargo.type) {
												duplicate = true;
											}
										});
										if (!duplicate) {
											transitionBarPaxDropoffArray.push(tempCargo);
											obj[i].history[0].cargoes = [...obj[i].history[0].cargoes, tempCargo];
										} else {
											transitionBarPaxDropoffArray.push(tempCargo);
										}

										//create pickup for only leg:
										tempCargoPickup = JSON.parse(JSON.stringify(tempCargo));
										tempCargoPickup.liftrequest_status = 'ui_pickup';

										var pickupDuplicate = false;
										obj[i].history[0].cargoes.map((cargo2) => {
											if (cargo2.liftrequest_id === tempCargoPickup.liftrequest_id && cargo2.liftrequest_status.includes('ui_pickup') && cargo2.count === tempCargoPickup.count && cargo2.type === tempCargoPickup.type) {
												pickupDuplicate = true;
											}
											return null;
										});

										if (!pickupDuplicate) {
											obj[i].history[0].cargoes = [...obj[i].history[0].cargoes, tempCargoPickup];
										}
									}

									if (obj[i + 1] !== undefined && obj[i + 1].history !== null && obj[i + 1].history[0] !== null) {
										if (obj[i + 1].history[0].cargoes === null || obj[i + 1].history[0].cargoes === undefined) {
											obj[i + 1].history[0].cargoes = [];
										}
										if (cargo.liftrequest_status.includes('only leg')) {
											//if i+1 is the last leg (i+1 = res.length -1) then add items to liftrequest_status dropoff_lastLeg
											if (i + 1 === res.length - 1) {
												tempCargo.liftrequest_status = 'ui_dropoff_lastLegDeparture';
											}

											//create pickup for only leg:
											tempCargoPickup = JSON.parse(JSON.stringify(tempCargo));
											tempCargoPickup.liftrequest_status = 'ui_pickup';

											pickupDuplicate = false;
											obj[i].history[0].cargoes.map((cargo2) => {
												if (cargo2.liftrequest_id === tempCargoPickup.liftrequest_id && cargo2.liftrequest_status.includes('ui_pickup') && cargo2.count === tempCargoPickup.count && cargo2.type === tempCargoPickup.type) {
													pickupDuplicate = true;
												}
												return null;
											});

											if (!pickupDuplicate) {
												obj[i].history[0].cargoes = [...obj[i].history[0].cargoes, tempCargoPickup];
											}

											obj[i].history[0].cargoes.map((cargo2) => {
												if (cargo2.liftrequest_id === tempCargo.liftrequest_id && cargo2.liftrequest_status.includes('ui_dropoff_lastLegDeparture') && cargo2.count === tempCargo.count && cargo2.type === tempCargo.type) {
													duplicate = true;
												}
												return null;
											});

											//check if object has already been processed with "only leg" option (prevents duplicate dropoff objects)
											if (obj[i].history[0].cargoes.length > 0) {
												obj[i].history[0].cargoes.map((cargo2) => {
													if (cargo2.liftrequest_id === tempCargo.liftrequest_id && cargo2.liftrequest_status.includes('ui_dropoff') && cargo2.count === tempCargo.count && cargo2.type === tempCargo.type) {
														duplicate = true;
													}
													return null;
												});
											}

											//if already processed
											if (duplicate) {
												//just add to transitionObj for easier submission
												if (tempCargo.type === 'PALLETS') {
													transitionBarPalletDropoffArray.push(tempCargo);
													originalPalletOnlyLegArray.push(cargo);
												} else if (tempCargo.type === 'PASSENGERS') {
													transitionBarPaxDropoffArray.push(tempCargo);
													originalPaxOnlyLegArray.push(cargo);
												}
												//else if not yet processed
											} else {
												//add to both the transition bar arrays AND to obj[i + 1]
												if (tempCargo.type === 'PALLETS') {
													transitionBarPalletDropoffArray.push(tempCargo);
													originalPalletOnlyLegArray.push(cargo);
												} else if (tempCargo.type === 'PASSENGERS') {
													transitionBarPaxDropoffArray.push(tempCargo);
													originalPaxOnlyLegArray.push(cargo);
												}
												obj[i + 1].history[0].cargoes = [...obj[i + 1].history[0].cargoes, tempCargo];
											}
										}

										if (cargo.liftrequest_status.includes('last_leg')) {
											//if i+1 is the LAST LEG (i+1 = res.length -1) then add items to liftrequest_status dropoff_lastLeg
											if (i + 1 === res.length - 1) {
												tempCargo.liftrequest_status = 'ui_dropoff_lastLegDeparture';
											}

											if (obj[i + 1].history[0].cargoes === null || obj[i + 1].history[0].cargoes === undefined) {
												obj[i + 1].history[0].cargoes = [];
											}

											//check if object has already been processed with "last_leg" option (prevents duplicate dropoff objects)
											// eslint-disable-next-line
											obj[i + 1].history[0].cargoes.map((cargo2) => {
												if (cargo2.liftrequest_status.includes('ui_dropoff') && cargo2.count === tempCargo.count && cargo2.type === tempCargo.type) {
													duplicate = true;
													tempCargo = cargo2;
												}
											});

											// eslint-disable-next-line
											obj[i].history[0].cargoes.map((cargo2) => {
												if (cargo2.liftrequest_status.includes('ui_dropoff') && cargo2.count === tempCargo.count && cargo2.type === tempCargo.type) {
													duplicate = true;
													tempCargo = cargo2;
												}
											});

											//if already processed
											if (duplicate) {
												//just add to transitionObj for easier submission
												if (tempCargo.type === 'PALLETS') {
													transitionBarPalletDropoffArray.push(tempCargo);
													originalPalletLastLegArray.push(cargo);
												} else if (tempCargo.type === 'PASSENGERS') {
													transitionBarPaxDropoffArray.push(tempCargo);
													originalPaxLastLegArray.push(cargo);
												}
												//else if not yet processed
											} else {
												//add to both the transition bar arrays AND to obj[i + 1]
												if (tempCargo.type === 'PALLETS') {
													transitionBarPalletDropoffArray.push(tempCargo);
													originalPalletLastLegArray.push(cargo);
												} else if (tempCargo.type === 'PASSENGERS') {
													transitionBarPaxDropoffArray.push(tempCargo);
													originalPaxLastLegArray.push(cargo);
												}
												obj[i + 1].history[0].cargoes = [...obj[i + 1].history[0].cargoes, tempCargo];
											}
											return null;
										}

										if (cargo.liftrequest_status.includes('on_board')) {
											if (tempCargo.type === 'PALLETS') {
												originalOnBoardArray.push(cargo);
												transitionBarPalletOnBoardArray.push(tempCargo);
											} else if (tempCargo.type === 'PASSENGERS') {
												originalOnBoardArray.push(cargo);
												transitionBarPaxOnBoardArray.push(tempCargo);
											}
										}
									}
								}
								return null;
							});
						}

						//add pickups to array
						if (obj[i].history[0].cargoes !== null) {
							// eslint-disable-next-line no-loop-func
							obj[i].history[0].cargoes.map((cargo, j) => {
								//build out cargo arrays for display
								if (cargo.liftrequest_status !== null && cargo.liftrequest_status === 'pickup') {
									let pickupProcessed = false;
									//check if pickup_ui is found for this pickup
									if (
										// eslint-disable-next-line
										obj[i].history[0].cargoes.map((cargo2) => {
											if (cargo2.liftrequest_status.includes('ui_pickup') && cargo2.count === cargo.count && cargo2.type === cargo.type) {
												pickupProcessed = true;
											}
										})
									)
										if (pickupProcessed) {
											// if processed (meaning there is already a ui_pickup in this mission) then don't do anything with this pickup because we will get all info from the ui_pickup that's already processed
											if (cargo.type === 'PALLETS') {
												//just add to transition bar so we can submit it easily
												originalPalletPickupArray.push(cargo);
											} else if (cargo.type === 'PASSENGERS') {
												originaPaxPickupArray.push(cargo);
											}
										} else {
											// if NOT processed, then we need to do create a tempCargo with "ui_pickup" and add it to mission and grab primaryPOC altPOC
											var pickupTempCargo = JSON.parse(JSON.stringify(cargo));
											pickupTempCargo.liftrequest_status = 'ui_pickup';

											ApiLiftRequestById(pickupTempCargo.liftrequest_id).then((res) => {
												if (res && res.history[0] && res.history[0].departure && res.history[0].departure.primary && res.history[0].departure.primary.firstName) {
													formattedPriPOCString = `${res.history[0].departure.poc.primary.firstName}, ${res.history[0].departure.poc.primary.lastName}, ${res.history[0].arrival.poc.primary.phoneNumber}, Unit:${res.history[0].arrival.poc.primary.unit.uic}`;
													pickupTempCargo.primaryPoc = formattedPriPOCString;
													formattedAltPOCString = `${res.history[0].departure.poc.alternate.firstName}, ${res.history[0].departure.poc.alternate.lastName}, ${res.history[0].arrival.poc.alternate.phoneNumber}, Unit:${res.history[0].arrival.poc.alternate.unit.uic}`;
													pickupTempCargo.alternatePoc = formattedAltPOCString;
												}

												if (res && res.history[0] !== null && res.history[0].cargoes !== null && res.history[0].cargoes[0].seniorTravelers !== null) {
													formattedVipTravelerString = `${res.history[0].cargoes[0].seniorTravelers[0].firstName}, ${res.history[0].cargoes[0].seniorTravelers[0].lastName}, ${res.history[0].cargoes[0].seniorTravelers[0].phoneNumber}, Unit:${res.history[0].cargoes[0].seniorTravelers[0].unit.uic}`;
													pickupTempCargo.seniorTravelers = formattedSeniorTravelerString;
												}

												if (res && res.history[0] !== null && res.history[0].cargoes !== null && res.history[0].cargoes[0].vipTravelers !== null) {
													formattedSeniorTravelerString = `${res.history[0].cargoes[0].vipTravelers[0].firstName}, ${res.history[0].cargoes[0].vipTravelers[0].lastName}, ${res.history[0].cargoes[0].vipTravelers[0].phoneNumber}, Unit:${res.history[0].cargoes[0].vipTravelers[0].unit.uic}`;
													pickupTempCargo.vipTraveler = formattedVipTravelerString;
												}
											});

											liftsRemaining += 1;
											pickupTempCargo.signOff = {};

											if (pickupTempCargo.type === 'PALLETS') {
												palletPickupArray.push(pickupTempCargo);
												originalPalletPickupArray.push(cargo);
												totalPalletPickup += pickupTempCargo.count;
											} else if (pickupTempCargo.type === 'PASSENGERS') {
												paxPickupArray.push(pickupTempCargo);
												originaPaxPickupArray.push(cargo);
												totalPaxPickup += pickupTempCargo.count;
											}
										}
								}

								//catch ui_pickup
								if (cargo.liftrequest_status !== null && cargo.liftrequest_status === 'ui_pickup') {
									ApiLiftRequestById(cargo.liftrequest_id).then((res) => {
										if (res && res.history[0] && res.history[0].departure && res.history[0].departure.primary && res.history[0].departure.primary.firstName) {
											formattedPriPOCString = `${res.history[0].departure.poc.primary.firstName}, ${res.history[0].departure.poc.primary.lastName}, ${res.history[0].arrival.poc.primary.phoneNumber}, Unit:${res.history[0].arrival.poc.primary.unit.uic}`;
											cargo.primaryPoc = formattedPriPOCString;
											formattedAltPOCString = `${res.history[0].departure.poc.alternate.firstName}, ${res.history[0].departure.poc.alternate.lastName}, ${res.history[0].arrival.poc.alternate.phoneNumber}, Unit:${res.history[0].arrival.poc.alternate.unit.uic}`;
											cargo.alternatePoc = formattedAltPOCString;
										}

										if (res && res.history[0] !== null && res.history[0].cargoes !== null && res.history[0].cargoes[0].seniorTravelers !== null) {
											formattedVipTravelerString = `${res.history[0].cargoes[0].seniorTravelers[0].firstName}, ${res.history[0].cargoes[0].seniorTravelers[0].lastName}, ${res.history[0].cargoes[0].seniorTravelers[0].phoneNumber}, Unit:${res.history[0].cargoes[0].seniorTravelers[0].unit.uic}`;
											cargo.seniorTravelers = formattedSeniorTravelerString;
										}

										if (res && res.history[0] !== null && res.history[0].cargoes !== null && res.history[0].cargoes[0].vipTravelers !== null) {
											formattedSeniorTravelerString = `${res.history[0].cargoes[0].vipTravelers[0].firstName}, ${res.history[0].cargoes[0].vipTravelers[0].lastName}, ${res.history[0].cargoes[0].vipTravelers[0].phoneNumber}, Unit:${res.history[0].cargoes[0].vipTravelers[0].unit.uic}`;
											cargo.vipTraveler = formattedVipTravelerString;
										}
									});

									if (cargo.signOff !== undefined && cargo.signOff !== null && cargo.signOff.firstName !== undefined && cargo.signOff.firstName !== null) {
										//liftsCompleted = summation of all leg cargoes that have status of ACCEPTED and action of pickup (ignoring repeated Lift request ids)
										liftsCompleted += 1;
									} else {
										//liftsRemaining = summation of all leg cargoes that DON'T have status of ACCEPTED and DO HAVE action of pickup (ignoring repeated Lift request ids)
										liftsRemaining += 1;
										cargo.signOff = {};
									}

									if (cargo.type === 'PALLETS') {
										palletPickupArray.push(cargo);
										totalPalletPickup += cargo.count;
									} else if (cargo.type === 'PASSENGERS') {
										paxPickupArray.push(cargo);
										totalPaxPickup += cargo.count;
									}
								}

								if ((cargo.liftrequest_status !== null && cargo.liftrequest_status.includes('ui_dropoff')) || cargo.liftrequest_status === 'ui_dropoff') {
									ApiLiftRequestById(cargo.liftrequest_id).then((res) => {
										if (res !== null && res.history !== null && res.history[0].arrival !== null && res.history[0].arrival.poc !== null && res.history[0].arrival.poc.primary !== null && res.history[0].arrival.poc.primary.firstName !== null) {
											formattedPriPOCString = `${res.history[0].arrival.poc.primary.firstName}, ${res.history[0].arrival.poc.primary.lastName}, ${res.history[0].arrival.poc.primary.phoneNumber}, Unit:${res.history[0].arrival.poc.primary.unit.uic}`;
											cargo.primaryPoc = formattedPriPOCString;
											formattedAltPOCString = `${res.history[0].arrival.poc.alternate.firstName}, ${res.history[0].arrival.poc.alternate.lastName}, ${res.history[0].arrival.poc.alternate.phoneNumber}, Unit:${res.history[0].arrival.poc.alternate.unit.uic}`;
											cargo.alternatePoc = formattedAltPOCString;
										}

										if (res && res.history[0] !== null && res.history[0].cargoes !== null && res.history[0].cargoes[0].seniorTravelers !== null) {
											formattedVipTravelerString = `${res.history[0].cargoes[0].seniorTravelers[0].firstName}, ${res.history[0].cargoes[0].seniorTravelers[0].lastName}, ${res.history[0].cargoes[0].seniorTravelers[0].phoneNumber}, Unit:${res.history[0].cargoes[0].seniorTravelers[0].unit.uic}`;
											cargo.seniorTravelers = formattedSeniorTravelerString;
										}

										if (res && res.history[0] !== null && res.history[0].cargoes !== null && res.history[0].cargoes[0].vipTravelers !== null) {
											formattedSeniorTravelerString = `${res.history[0].cargoes[0].vipTravelers[0].firstName}, ${res.history[0].cargoes[0].vipTravelers[0].lastName}, ${res.history[0].cargoes[0].vipTravelers[0].phoneNumber}, Unit:${res.history[0].cargoes[0].vipTravelers[0].unit.uic}`;
											cargo.vipTraveler = formattedVipTravelerString;
										}
									});

									if (processed) {
										let alreadyMoved = false;

										// check if this cargo has already been moved forward for dropoff
										if (obj[i - 1].history[0].cargoes === null || obj[i - 1].history[0].cargoes === undefined) {
											obj[i - 1].history[0].cargoes = [];
										}

										// eslint-disable-next-line
										obj[i - 1].history[0].cargoes.map((cargo2) => {
											if ((cargo2.liftrequest_status.includes('ui_dropoff') || cargo2.liftrequest_status.includes('only leg')) && cargo2.count === cargo.count && cargo2.type === cargo.type) {
												alreadyMoved = true;
											}
										});

										if (alreadyMoved) {
											// if yes already moved forward then add to this pallet/pax dropoff array and continue
											if (cargo.type === 'PALLETS') {
												if (cargo.signOff === undefined || cargo.signOff === null || cargo.signOff.firstName === null || cargo.signOff.firstName === undefined) {
													cargoPalletCompletedDropoff = false;
													cargo.signOff = {};
												}
												if (cargo.liftrequest_status.includes('lastLegDeparture')) {
													palletDropoffArrayLastLegDep.push(cargo);
													totalPalletDropoffLastLegDep += cargo.count;
												} else if (cargo.liftrequest_status.includes('lastLegArrival')) {
													palletDropoffArrayLastLegArr.push(cargo);
													totalPalletDropoffLastLegArr += cargo.count;
												} else {
													palletDropoffArray.push(cargo);
													totalPalletDropoff += cargo.count;
												}
											}
											if (cargo.type === 'PASSENGERS') {
												if (cargo.signOff === undefined || cargo.signOff === null || cargo.signOff.firstName === null || cargo.signOff.firstName === undefined) {
													cargoPaxCompletedDropoff = false;
													cargo.signOff = {};
												}
												if (cargo.liftrequest_status.includes('lastLegDeparture')) {
													paxDropoffArrayLastLegDep.push(cargo);
													totalPaxDropoffLastLegDep += cargo.count;
												} else if (cargo.liftrequest_status.includes('lastLegArrival')) {
													paxDropoffArrayLastLegArr.push(cargo);
													totalPaxDropoffLastLegArr += cargo.count;
												} else {
													paxDropoffArray.push(cargo);
													totalPaxDropoff += cargo.count;
												}
											}
											//if NOT a moved cargo then move it to obj[i+1]
										} else {
											if (obj[i + 1].history[0].cargoes === null) {
												obj[i + 1].history[0].cargoes = [];
											}
											obj[i + 1].history[0].cargoes = [...obj[i + 1].history[0].cargoes, cargo];
										}
									} else {
										if (cargo.type === 'PALLETS') {
											if (cargo.signOff === undefined || cargo.signOff === null || cargo.signOff.firstName === null || cargo.signOff.firstName === undefined) {
												cargoPalletCompletedDropoff = false;
												cargo.signOff = {};
											}

											if (cargo.liftrequest_status.includes('lastLegDeparture')) {
												palletDropoffArrayLastLegDep.push(cargo);
												totalPalletDropoffLastLegDep += cargo.count;
											} else if (cargo.liftrequest_status.includes('lastLegArrival')) {
												palletDropoffArrayLastLegArr.push(cargo);
												totalPalletDropoffLastLegArr += cargo.count;
											} else {
												palletDropoffArray.push(cargo);
												totalPalletDropoff += cargo.count;
											}
										}
										if (cargo.type === 'PASSENGERS') {
											if (cargo.signOff === undefined || cargo.signOff === null || cargo.signOff.firstName === null || cargo.signOff.firstName === undefined) {
												cargoPaxCompletedDropoff = false;
												cargo.signOff = {};
											}
											if (cargo.liftrequest_status.includes('lastLegDeparture')) {
												paxDropoffArrayLastLegDep.push(cargo);
												totalPaxDropoffLastLegDep += cargo.count;
											} else if (cargo.liftrequest_status.includes('lastLegArrival')) {
												paxDropoffArrayLastLegArr.push(cargo);
												totalPaxDropoffLastLegArr += cargo.count;
											} else {
												paxDropoffArray.push(cargo);
												totalPaxDropoff += cargo.count;
											}
										}
									}
								}
								return null;
							});
						}
					}

					if (palletPickupArray.length > 0) {
						// eslint-disable-next-line
						palletPickupArray.map((pickup) => {
							if (pickup.signOff === undefined || pickup.signOff.firstName === undefined || pickup.signOff.lastName === undefined) {
								cargoPalletCompletedPickup = false;
							}
						});
					} else {
						cargoPalletCompletedPickup = false;
					}

					if (paxPickupArray.length > 0) {
						// eslint-disable-next-line
						paxPickupArray.map((pickup) => {
							if (pickup.signOff === undefined || pickup.signOff.firstName === undefined || pickup.signOff.lastName === undefined) {
								cargoPaxCompletedPickup = false;
							}
						});
					} else {
						cargoPaxCompletedPickup = false;
					}

					// if first obj/leg, stop after getting departure and pickup info
					if (i === 0 && res.length !== 1) {
						var newjsonObject = createLegObject(obj, i, missionId, paxPickupArray, palletPickupArray, totalPalletPickup, totalPaxPickup, cargoPalletCompletedPickup, cargoPaxCompletedPickup, palletDropoffArray, paxDropoffArray, totalPalletDropoff, totalPaxDropoff, cargoPaxCompletedDropoff, cargoPalletCompletedDropoff, legStatus, true, false);
						jsonArray.push(newjsonObject);

						//push a transition obj just containing title and a boolean that it's a transition panelbar
						var transitionObject = createTransitionObject(obj, i, titleString, legStatus, originalPalletOnlyLegArray, originalPaxOnlyLegArray, originalPalletLastLegArray, originalPaxLastLegArray, originaPaxPickupArray, originalPalletPickupArray, transitionBarPaxLastLegArray, transitionBarPalletLastLegArray, transitionBarPalletDropoffArray, transitionBarPaxDropoffArray, originalOnBoardArray, transitionBarPaxOnBoardArray, transitionBarPalletOnBoardArray, paxPickupArray, palletPickupArray, false);
						jsonArray.push(transitionObject);
					}

					// if NOT last leg and NOT first leg - get everything
					if (i !== 0 && i !== res.length - 1) {
						newjsonObject = createLegObject(obj, i, missionId, paxPickupArray, palletPickupArray, totalPalletPickup, totalPaxPickup, cargoPalletCompletedPickup, cargoPaxCompletedPickup, palletDropoffArray, paxDropoffArray, totalPalletDropoff, totalPaxDropoff, cargoPaxCompletedDropoff, cargoPalletCompletedDropoff, legStatus, false, false);
						jsonArray.push(newjsonObject);

						transitionObject = createTransitionObject(obj, i, titleString, legStatus, originalPalletOnlyLegArray, originalPaxOnlyLegArray, originalPalletLastLegArray, originalPaxLastLegArray, originaPaxPickupArray, originalPalletPickupArray, transitionBarPaxLastLegArray, transitionBarPalletLastLegArray, transitionBarPalletDropoffArray, transitionBarPaxDropoffArray, originalOnBoardArray, transitionBarPaxOnBoardArray, transitionBarPalletOnBoardArray, paxPickupArray, palletPickupArray, false);
						jsonArray.push(transitionObject);
					}

					// if last obj/leg, push a third (final) custom legObject, and add a "lastLeg" attrbute to the transition object so that it is excluded from locking cargo rules
					if (i === res.length - 1) {
						if (res.length === 1) {
							newjsonObject = createLegObject(obj, i, missionId, paxPickupArray, palletPickupArray, totalPalletPickup, totalPaxPickup, cargoPalletCompletedPickup, cargoPaxCompletedPickup, palletDropoffArrayLastLegDep, paxDropoffArrayLastLegDep, totalPalletDropoffLastLegDep, totalPaxDropoffLastLegDep, cargoPaxCompletedDropoff, cargoPalletCompletedDropoff, legStatus, true, false);
							jsonArray.push(newjsonObject);
						} else {
							newjsonObject = createLegObject(obj, i, missionId, paxPickupArray, palletPickupArray, totalPalletPickup, totalPaxPickup, cargoPalletCompletedPickup, cargoPaxCompletedPickup, palletDropoffArrayLastLegDep, paxDropoffArrayLastLegDep, totalPalletDropoffLastLegDep, totalPaxDropoffLastLegDep, cargoPaxCompletedDropoff, cargoPalletCompletedDropoff, legStatus, false, false);
							jsonArray.push(newjsonObject);
						}

						transitionObject = createTransitionObject(obj, i, titleString, legStatus, originalPalletOnlyLegArray, originalPaxOnlyLegArray, originalPalletLastLegArray, originalPaxLastLegArray, originaPaxPickupArray, originalPalletPickupArray, transitionBarPaxLastLegArray, transitionBarPalletLastLegArray, transitionBarPalletDropoffArray, transitionBarPaxDropoffArray, originalOnBoardArray, transitionBarPaxOnBoardArray, transitionBarPalletOnBoardArray, paxPickupArray, palletPickupArray, true);
						jsonArray.push(transitionObject);

						newjsonObject = createLegObject(obj, i, missionId, null, null, null, null, null, null, palletDropoffArrayLastLegArr, paxDropoffArrayLastLegArr, totalPalletDropoffLastLegArr, totalPaxDropoffLastLegArr, cargoPaxCompletedDropoff, cargoPalletCompletedDropoff, legStatus, false, true);
						jsonArray.push(newjsonObject);
					}
					i++;
				}

				if (totalDistance !== 0) {
					totalDistanceInMiles = totalDistance * 0.000621371;
				}

				if (latestDate !== '' && earliestDate !== '' && earliestDate !== null && latestDate !== null) {
					// calculate number of days between mission start and mission end
					var totalMissionTime = (latestDate.getTime() - earliestDate.getTime()) / (1000 * 3600 * 24);

					//calculate remaining vs completed days
					// completed days - subtraction of current date from first date (set to 0 if negative)
					const completed = Math.round(Math.abs((today - earliestDate) / oneDay));
					daysCompleted = completed >= 0 ? completed : 0;
					// remaining days - subtraction of final or latest possible date from the current date(set to 0 if negative)
					const remaining = Math.round((latestDate - today) / oneDay);
					daysRemaining = remaining >= 0 ? remaining : 0;

					var statsObj1 = {
						missionStartDateTime: earliestDate,
						missionEndDateTime: latestDate,
						completedLegs: legsCompleted,
						remainingLegs: legsRemaining,
						completedDays: daysCompleted,
						remainingDays: daysRemaining,
						completedLifts: liftsCompleted,
						remainingLifts: liftsRemaining,
						totalMissionTime: totalMissionTime.toFixed(1),
						totalFlightTime: totalFlightTime.toFixed(1),
						totalDistance: totalDistanceInMiles.toFixed(0)
					};
					statsObj.push(statsObj1);
				}

				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log('leg data using new object structure: ', jsonArray);
				}

				setLegData(jsonArray);
				setStatsData(statsObj);
				setLoaded(true);
				handleVisualData();
			});
		}
	};

	const missionsListDataFetch = () => {
		const queryparams = '?state=GENERATED&state=DEPARTED';
		ApiMissionListByState(queryparams).then((res) => {
			setMissionsList(res);
		});
	};

	const scrollToElement = () => {
		const element = document.getElementById(missionId);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	useEffect(() => {
		// grab response of apiMissionsList for all available missions
		missionsListDataFetch();
		//use missionslist api response to build missionslist api data to build mission navigation column
		missionsNavigationFetch();
		// grab response of missionById using selected mission
		missionDataFetch();
		scrollToElement();
	}, []);

	useEffect(() => {
		//using missionId of selected mission, run apiLegsByMissionID and make initial legData Obj
		legsDataFetch();
		// build map and gantt data with legData built in legsDataFetch
		handleVisualData();
		setLegReload(Math.random());
		missionDataFetch();
		setExpanded([]);
		//set default view, statsbar and mapview
		setMapView(true);
		setStatsView(true);
		setStatsExpanded(false);
		setGanttView(false);
	}, [navigate]);

	useEffect(() => {
		scrollToElement();
	}, [missionsList]);

	useEffect(() => {
		handleVisualData();
	}, [legData]);

	const missionsNavigationFetch = () => {
		missionsList.map((rec, i) => {
			const mId = rec.id;

			ApiLegsById(mId).then((res) => {
				const jsonArray = [];
				var i = 0;
				while (i < res.length) {
					var from = res[i].history[0].departure !== null ? res[i].history[0].departure.airport.icao : null;
					var to = res[i].history[0].arrival.airport.icao;
					var titleString = from + ' to ' + to;
					var jsonObject = {
						id: res[i].id,
						start: res[i].history[0].departure !== null ? new Date(stripTimeZone(res[i].history[0].departure.planned)) : null,
						end: new Date(stripTimeZone(res[i].history[0].arrival.planned)),
						arrivalAirport: res[i].history[0].arrival.airport.icao,
						arrivalAirportLat: res[i].history[0].arrival.airport.latitude,
						arrivalAirportLong: res[i].history[0].arrival.airport.longitude,
						departureAirport: res[i].history[0].departure !== null ? res[i].history[0].departure.airport.icao : null,
						departureAirportLat: res[i].history[0].departure !== null ? res[i].history[0].departure.airport.latitude : null,
						departureAirportLong: res[i].history[0].departure !== null ? res[i].history[0].departure.airport.longitude : null,
						title: titleString
					};
					jsonArray.push(jsonObject);
					i++;
				}
			});
			return null;
		});
	};

	// Mission Sidebar Selection List
	const missionsNavigation = () => {
		return (
			<div className={'mission-schedules-list'}>
				<div className={'mission-schedules-toggle'}>
					<Navbar bg="light" expand="lg">
						<Button
							className={`mission-schedules-list-button btn-list-missions-view ${missionList ? 'show-list' : 'hide-list'}`}
							title={`Click to ${missionList ? 'Hide' : 'Show'} Mission List`}
							onClick={() => {
								setMissionList(!missionList);
							}}>
							<i className={'fa fa-bars'}></i>
							<span>{`${missionList ? 'Hide' : 'Show'}`} Mission List</span>
						</Button>
					</Navbar>
				</div>

				<div className={'mission-schedules-list-inner'}>
					<ul key={'missionList'}>
						{missionsList.map((rec, i) => {
							const missionName = rec.missionName === null ? rec.id : rec.missionName;
							const mId = rec.id;
							const createdAt = moment(rec.history[0].createdAt).tz('America/New_York').format('YYYY-MM-DD');
							const timeframe = {
								__html: '<strong>' + missionName + '</strong><span class="daterange">Created on ' + createdAt + '</span>'
							};

							return (
								<li key={i} id={mId}>
									<NavLink to={`/missionstatus/${mId}`} title={`View Mission Status for ${mId}`}>
										<span dangerouslySetInnerHTML={timeframe} />
									</NavLink>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};

	// Header Filters
	const headerFilterList = () => {
		return (
			<Navbar expand={'lg'}>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						{/* Print and export button placeholders to be implemented in ALO-549 */}
						{/* <div className={"nav-link-group"}>
                            <Nav.Link href="#print" className={"icon-only"}>
                                <i className={"fa fa-print"}></i>
                                <span className={"Print"}></span>
                            </Nav.Link>
                            <Nav.Link href="#export" className={"icon-only"}>
                                <i className={"fa fa-file-export"}></i>
                                <span className={"Export"}></span>
                            </Nav.Link>
                        </div> */}

						{accessValidator(props.roleaccess, accessMissionEdit) && (
							<div className={'nav-link-group'}>
								<NavLink to={`/mission/edit/${missionId}`} className={'nav-link btn-edit-mission icon-only'} title={'Click to Edit Mission'}>
									<i className={'fa fa-edit padding-right-8'}></i>
									<span>Edit Mission</span>
								</NavLink>
							</div>
						)}

						<div className={'nav-link-group'}>
							<NavLink to={`${missionId ? '/schedule/map/' + missionId : '/schedule/map'}`} className={'nav-link btn-map-view icon-only'} title={'View Map of Selected Mission'}>
								<i className={'fa fa-globe'}></i>
								<span className={'sr-only'}>Click for Map View</span>
							</NavLink>
							<NavLink to={`${missionId ? '/missionstatus/' + missionId : '/missionstatus'}`} className={'nav-link btn-missionstatus-view icon-only active'} title={'View Mission Status for Selected Mission'}>
								<i className={'fa fa-plane-departure'}></i>
								<span className={'sr-only'}>Click for Mission Status</span>
							</NavLink>
							<NavLink to={`${missionId ? '/schedule/legs/' + missionId : '/schedule/legs'}`} className={'nav-link btn-legs-view icon-only'} title={'View Legs of Selected Mission'}>
								<i className={'fa fa-table-rows'}></i>
								<span className={'sr-only'}>Click for Mission Legs View</span>
							</NavLink>
							<NavLink to={`${missionId ? '/radarbox/map/' + missionId : '/radarbox/map'}`} className={'nav-link btn-map-view icon-only'} title={'View RadarBox AirNav Map'}>
								<i className={'fa-solid fa-radar'}></i>
								<span className={'sr-only'}>Click for RadarBox AirNav Map</span>
							</NavLink>
						</div>

						<div className={'nav-link-group'}>
							{windowView === 'minimize' ? (
								<Nav.Link
									href="#maximize"
									className={'btn-maximize-view icon-only'}
									onClick={() => {
										setWindowView('maximize');
									}}>
									<i className={'fa fa-arrows-maximize'}></i>
									<span className={'sr-only'}>Full Screen</span>
								</Nav.Link>
							) : (
								<Nav.Link
									href="#minimize"
									className={'btn-minimize-view icon-only'}
									onClick={() => {
										setWindowView('minimize');
									}}>
									<i className={'fa fa-arrows-minimize'}></i>
									<span className={'sr-only'}>Exit Full Screen</span>
								</Nav.Link>
							)}
						</div>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	const titleBarSectionToggles = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="toggle-navbar-nav" />
				<Navbar.Collapse id="toggle-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link
							href="#ms-stats-toggle"
							onClick={() => {
								handleStatsToggle();
							}}>
							<i className={'fa fa-file-chart-column'}></i>
							<span>{statsView ? 'Hide' : 'Show'} Stats</span>
						</Nav.Link>
						<Nav.Link
							href="#ms-gantt-toggle"
							onClick={() => {
								handleGanttToggle();
							}}>
							<i className={'fa fa-chart-bar'}></i>
							<span>{ganttView ? 'Hide' : 'Show'} Gantt Chart</span>
						</Nav.Link>
						<Nav.Link
							href="#ms-map-toggle"
							onClick={() => {
								handleMapToggle();
							}}>
							<i className={'fa fa-map-location-dot'}></i>
							<span>{mapView ? 'Hide' : 'Show'} Map View</span>
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	//recalculate stats data, map through legs and check val
	function checkLegStatistics(rec) {
		let completedLifts = 0;
		let remainingLifts = 0;
		let completedLegs = 0;
		let remainingLegs = 0;
		let tempStatsArray = [];
		let tempStatsObj = [];
		legData.map((leg, i) => {
			if (leg.transitionBar === true) {
				if (leg.cargoPaxPickup.length > 0) {
					// eslint-disable-next-line
					leg.cargoPaxPickup.map((pickup) => {
						if (pickup.signOff !== undefined && pickup.signOff !== null && pickup.signOff.firstName !== undefined && pickup.signOff.firstName !== null) {
							completedLifts += 1;
						} else {
							remainingLifts += 1;
						}
					});
				}
				if (leg.cargoPalletsPickup.length > 0) {
					// eslint-disable-next-line
					leg.cargoPalletsPickup.map((pickup) => {
						if (pickup.signOff !== undefined && pickup.signOff !== null && pickup.signOff.firstName !== undefined && pickup.signOff.firstName !== null) {
							completedLifts += 1;
						} else {
							remainingLifts += 1;
						}
					});
				}
				if (leg.status === 'Completed') {
					completedLegs += 1;
				} else {
					remainingLegs += 1;
				}
			}
			return null;
		});
		tempStatsObj = {
			...statsData[0],
			completedLegs: completedLegs,
			remainingLegs: remainingLegs,
			completedLifts: completedLifts,
			remainingLifts: remainingLifts
		};

		tempStatsArray.push(tempStatsObj);
		setStatsData(tempStatsArray);
		return null;
	}

	// check all leg fields for completion, if complete, set check status to "complete". if SOME are complete, set check status to "started".
	// if NONE complete, set check status to "notStarted"
	function checkLegStatus(i = null, recParam = null) {
		const statusCheckArray = [];
		let rec = {};
		// if only i is given, we are checking legData[i] directly. if recParam is given, we are checking only the given rec
		if (recParam === null) {
			rec = legData[i];
		} else if (i === null) {
			rec = recParam;
		}
		if (rec.arrival !== null && rec.arrival.actual !== null && rec.arrival !== undefined) {
			statusCheckArray.push('true');
		} else {
			statusCheckArray.push('false');
		}

		if (rec.departure !== null && rec.departure.actual !== null && rec.departure !== undefined) {
			statusCheckArray.push('true');
		} else {
			statusCheckArray.push('false');
		}

		//loop through each cargo item in leg. if any of them do NOT have a signoff object (confirmed completion) then the array will contain
		//"false" and the leg is flagged as "not complete"
		if (rec.cargoPalletsDropoff !== null && rec.cargoPalletsDropoff !== undefined) {
			rec.cargoPalletsDropoff.map((cargo) => {
				if (cargo !== undefined && cargo.signOff !== null && cargo.signOff !== undefined && cargo.signOff.firstName !== null && cargo.signOff.firstName !== undefined) {
					statusCheckArray.push('true');
				} else {
					statusCheckArray.push('false');
				}
				return null;
			});
		}

		if (rec.cargoPalletsPickup !== null && rec.cargoPalletsPickup !== undefined) {
			rec.cargoPalletsPickup.map((cargo) => {
				if (cargo !== undefined && cargo.signOff !== null && cargo.signOff !== undefined && cargo.signOff.firstName !== null && cargo.signOff.firstName !== undefined) {
					statusCheckArray.push('true');
				} else {
					statusCheckArray.push('false');
				}
				return null;
			});
		}

		if (rec.cargoPaxDropoff !== null && rec.cargoPaxDropoff !== undefined) {
			rec.cargoPaxDropoff.map((cargo) => {
				if (cargo !== undefined && cargo.signOff !== null && cargo.signOff !== undefined && cargo.signOff.firstName !== null && cargo.signOff.firstName !== undefined) {
					statusCheckArray.push('true');
				} else {
					statusCheckArray.push('false');
				}
				return null;
			});
		}

		if (rec.cargoPaxPickup !== null && rec.cargoPaxPickup !== undefined) {
			rec.cargoPaxPickup.map((cargo) => {
				if (cargo !== undefined && cargo !== null && cargo.signOff !== undefined && cargo.signOff !== null && cargo.signOff.firstName !== null && cargo.signOff.firstName !== undefined) {
					statusCheckArray.push('true');
				} else {
					statusCheckArray.push('false');
				}
				return null;
			});
		}

		if (rec.cargoes !== null && rec.cargoes !== undefined) {
			rec.cargoes.map((cargo) => {
				if (cargo !== undefined && cargo !== null && cargo.signOff !== undefined && cargo.signOff !== null && cargo.signOff.firstName !== null && cargo.signOff.firstName !== undefined) {
					statusCheckArray.push('true');
				} else {
					statusCheckArray.push('false');
				}
				return null;
			});
		}

		if (statusCheckArray.length > 0) {
			if (recParam === null) {
				if (statusCheckArray.every((value) => value === 'true')) {
					legData[i].status = 'Completed';
				} else if (statusCheckArray.every((value) => value === 'false')) {
					legData[i].status = 'Not Started';
				} else {
					legData[i].status = 'Not Completed';
				}
				return null;
			} else if (i === null) {
				if (statusCheckArray.every((value) => value === 'true')) {
					return 'Completed';
				} else if (statusCheckArray.every((value) => value === 'false')) {
					return 'Not Started';
				} else {
					return 'Not Completed';
				}
			}
		}
	}

	const checkMissionStatus = () => {
		// check status of entire mission
		const missionStatusCheckArray = [];
		legData.map((leg) => {
			if (leg.transitionBar) {
				if (leg.status === 'Completed') {
					missionStatusCheckArray.push('true');
				} else if (leg.status === 'Not Completed') {
					missionStatusCheckArray.push('false');
				} else if (leg.status === 'Not Started') {
					missionStatusCheckArray.push('false');
				}
			}
			return null;
		});
		if (missionStatusCheckArray.every((value) => value === 'true')) {
			ApiChangeMissionState(missionId, 'COMPLETED');
			apiMissionData.history[0].state = 'COMPLETED';
			setApiMissionData(apiMissionData);
		} else if (missionStatusCheckArray.every((value) => value === 'false')) {
			ApiChangeMissionState(missionId, 'GENERATED');
			apiMissionData.history[0].state = 'GENERATED';
			setApiMissionData(apiMissionData);
		} else {
			ApiChangeMissionState(missionId, 'DEPARTED');
			apiMissionData.history[0].state = 'DEPARTED';
			setApiMissionData(apiMissionData);
		}
	};

	const handleExpandedStats = () => {
		setStatsExpanded(!statsExpanded);
	};

	const submit = (index) => {
		//once we hit the leg that has changes, we map to our json api object and then send to api
		legData.map((leg, i) => {
			if (i === index) {
				var cargoArray = [];
				if (leg.cargoPalletsDropoff !== null && leg.cargoPalletsDropoff !== undefined) {
					leg.cargoPalletsDropoff.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}

				if (leg.cargoPalletsPickup !== null && leg.cargoPalletsPickup !== undefined) {
					leg.cargoPalletsPickup.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}

				if (leg.cargoPaxDropoff !== null && leg.cargoPaxDropoff !== undefined) {
					leg.cargoPaxDropoff.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}

				if (leg.cargoPaxPickup !== null && leg.cargoPaxPickup !== undefined) {
					leg.cargoPaxPickup.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}

				//track original pickups
				if (leg.originalCargoPaxPickup !== null && leg.originalCargoPaxPickup !== undefined) {
					leg.originalCargoPaxPickup.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}
				if (leg.originalCargoPalletPickup !== null && leg.originalCargoPalletPickup !== undefined) {
					leg.originalCargoPalletPickup.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}

				//track original onBoards
				if (leg.originalOnBoard !== null && leg.originalOnBoard !== undefined) {
					leg.originalOnBoard.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}

				//track original last leg
				if (leg.originalCargoPaxLastLeg !== null && leg.originalCargoPaxLastLeg !== undefined) {
					leg.originalCargoPaxLastLeg.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}

				if (leg.originalCargoPalletLastLeg !== null && leg.originalCargoPalletLastLeg !== undefined) {
					leg.originalCargoPalletLastLeg.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}

				//track original only leg
				if (leg.originalCargoPaxOnlyLeg !== null && leg.originalCargoPaxOnlyLeg !== undefined) {
					leg.originalCargoPaxOnlyLeg.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}

				if (leg.originalCargoPalletOnlyLeg !== null && leg.originalCargoPalletOnlyLeg !== undefined) {
					leg.originalCargoPalletOnlyLeg.map((cargo, i) => {
						return cargoArray.push(cargo);
					});
				}

				const submitData = {
					arrival: leg.arrival,
					departure: leg.departure,
					createdBy: leg.createdBy,
					createdAt: leg.createdAt,
					aircraftConfigurationId: leg.aircraftConfigurationId,
					reconfigurationEvent: leg.reconfigurationEvent,
					cargoes: cargoArray,
					satisfiesLiftRequests: leg.satisfiesLiftRequests,
					missionId: leg.missionId,
					state: leg.state,
					version: leg.version,
					assetId: leg.assetId
				};
				const senddata = JSON.stringify(submitData);

				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log('data to submit: ', submitData);
				}

				ApiUpdateLegHistoryByIdForm(leg.id, senddata).then((res) => {});
			}
			return null;
		});
	};

	const handleChange = (i, event, field, n = 1) => {
		if (field === 'arrival') {
			const values = [...arrivalFields];
			values[i] = event.target.value;
			setArrivalFields(values);
		}
		if (field === 'departure') {
			const values = [...departureFields];
			values[i] = event.target.value;
			setDepartureFields(values);
		}
		if (field === 'palletPickupFirst') {
			const values = [...palletPickupFieldsFirst];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPalletPickupFieldsFirst(values);
		}
		if (field === 'palletPickupLast') {
			const values = [...palletPickupFieldsLast];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPalletPickupFieldsLast(values);
		}
		if (field === 'palletDropoffFirst') {
			const values = [...palletDropoffFieldsFirst];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPalletDropoffFieldsFirst(values);
		}
		if (field === 'palletDropoffLast') {
			const values = [...palletDropoffFieldsLast];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPalletDropoffFieldsLast(values);
		}
		if (field === 'paxPickupFirst') {
			const values = [...paxPickupFieldsFirst];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPaxPickupFieldsFirst(values);
		}
		if (field === 'paxPickupLast') {
			const values = [...paxPickupFieldsLast];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPaxPickupFieldsLast(values);
		}
		if (field === 'paxDropoffFirst') {
			const values = [[...paxDropoffFieldsFirst]];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPaxDropoffFieldsFirst(values);
		}
		if (field === 'paxDropoffLast') {
			const values = [...paxDropoffFieldsLast];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPaxDropoffFieldsLast(values);
		}
		if (field === 'paxDropoffCount') {
			const values = [[...paxDropoffFieldsFirst]];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPaxDropoffFieldsCount(values);
		}
		if (field === 'paxPickupCount') {
			const values = [[...paxPickupFieldsCount]];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPaxPickupFieldsCount(values);
		}
		if (field === 'palletPickupNotes') {
			const values = [...palletPickupNotes];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPalletPickupNotes(values);
		}
		if (field === 'palletDropoffNotes') {
			const values = [...palletDropoffNotes];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPalletDropoffNotes(values);
		}
		if (field === 'paxDropoffNotes') {
			const values = [...paxDropoffNotes];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPaxDropoffNotes(values);
		}
		if (field === 'paxPickupNotes') {
			const values = [...paxPickupNotes];
			values[i] = [];
			values[n] = [];
			values[i][n] = event.target.value;
			setPaxPickupNotes(values);
		}
	};

	const ArrivalButtonContainer = (i) => {
		const handleClick = () => {
			legData[i].arrival.actual = forceDateStringToUTCISOFormat(arrivalFields[i]);
			legData[i - 1].arrival.actual = forceDateStringToUTCISOFormat(arrivalFields[i]);
			checkLegStatus(i - 1, null);
			setLegData(legData);
			checkMissionStatus();
			checkLegStatistics(legData[i - 1]);
			setLegReload(Math.random());
			submit(i - 1);
		};

		return (
			<Button disabled={arrivalFields[i] ? false : true} className={'btn button btn-primary btn-color-green'} id={'arrival'} onClick={handleClick} title={'Click to Confirm Arrival'}>
				Confirm Arrival<i className={'far fa-check'}></i>
			</Button>
		);
	};

	const DepartureButtonContainer = (i) => {
		const handleClick = () => {
			legData[i].departure.actual = forceDateStringToUTCISOFormat(departureFields[i]);
			legData[i + 1].departure.actual = forceDateStringToUTCISOFormat(departureFields[i]);
			checkLegStatus(i + 1, null);
			setLegData(legData);
			checkMissionStatus();
			checkLegStatistics(legData[i + 1]);
			setLegReload(Math.random());
			submit(i + 1);
		};

		return (
			<Button disabled={departureFields[i] ? false : true} className={'btn button btn-primary btn-color-green'} id={'departure'} onClick={handleClick} title={'Click to Confirm Take-off'}>
				Confirm Departure<i className={'far fa-check'}></i>
			</Button>
		);
	};

	const checkLiftRequestCompleted = (liftRequestId) => {
		var completedLiftRequest = true;

		// eslint-disable-next-line
		legData.map((leg) => {
			if (leg.transitionBar === false) {
				if ((leg.cargoPalletsDropoff !== null) & (leg.cargoPalletsDropoff !== undefined) && leg.cargoPalletsDropoff.length > 0) {
					// eslint-disable-next-line
					leg.cargoPalletsDropoff.map((cargo) => {
						if (cargo.liftrequest_id === liftRequestId) {
							if (cargo.signOff === undefined || cargo.signOff === null || cargo.signOff.firstName === null || cargo.signOff.firstName === undefined || cargo.signOff.lastName === null || cargo.signOff.lastName === undefined) {
								completedLiftRequest = false;
							}
						}
					});
				}
				if ((leg.cargoPalletsPickup !== null) & (leg.cargoPalletsPickup !== undefined) && leg.cargoPalletsPickup.length > 0) {
					// eslint-disable-next-line
					leg.cargoPalletsPickup.map((cargo) => {
						if (cargo.liftrequest_id === liftRequestId) {
							if (cargo.signOff === undefined || cargo.signOff === null || cargo.signOff.firstName === null || cargo.signOff.firstName === undefined || cargo.signOff.lastName === null || cargo.signOff.lastName === undefined) {
								completedLiftRequest = false;
							}
						}
					});
				}
				if ((leg.cargoPaxDropoff !== null) & (leg.cargoPaxDropoff !== undefined) && leg.cargoPaxDropoff.length > 0) {
					// eslint-disable-next-line
					leg.cargoPaxDropoff.map((cargo) => {
						if (cargo.liftrequest_id === liftRequestId) {
							if (cargo.signOff === undefined || cargo.signOff === null || cargo.signOff.firstName === null || cargo.signOff.firstName === undefined || cargo.signOff.lastName === null || cargo.signOff.lastName === undefined) {
								completedLiftRequest = false;
							}
						}
					});
				}
				if ((leg.cargoPaxPickup !== null) & (leg.cargoPaxPickup !== undefined) && leg.cargoPaxPickup.length > 0) {
					// eslint-disable-next-line
					leg.cargoPaxPickup.map((cargo) => {
						if (cargo.liftrequest_id === liftRequestId) {
							if (cargo.signOff === undefined || cargo.signOff === null || cargo.signOff.firstName === null || cargo.signOff.firstName === undefined || cargo.signOff.lastName === null || cargo.signOff.lastName === undefined) {
								completedLiftRequest = false;
							}
						}
					});
				}
			}
		});
		return completedLiftRequest;
	};

	const PalletDropoffButtonContainer = (i, n) => {
		const handleClick = () => {
			let signOffObj = {
				firstName: palletDropoffFieldsFirst[i][n],
				lastName: palletDropoffFieldsLast[i][n]
			};
			legData[i].cargoPalletsDropoff[n].signOff = signOffObj;
			legData[i - 1].cargoPalletsDropoff[n].signOff = signOffObj;
			setLegData(legData);
			checkMissionStatus();
			legData[i].palletCompletedDropoffCheck = checkCargoCompleted(legData[i].cargoPalletsDropoff);
			submit(i - 1);

			//check if this is the final dropoff (all other pickups and dropoffs for this lift requestId in this mission are signed-off)
			//check for completed lift request
			if (legData[i - 1].satisfiesLiftRequests !== undefined && legData[i].satisfiesLiftRequests !== null && legData[i - 1].satisfiesLiftRequests.length > 0) {
				// eslint-disable-next-line
				legData[i - 1].satisfiesLiftRequests.map((liftRequestId) => {
					var liftRequestCompleted = checkLiftRequestCompleted(liftRequestId);
					if (liftRequestCompleted === true) {
						ApiChangeLiftRequestState(liftRequestId, 'SATISFIED');
					}
				});
			}
		};

		return (
			<div>
				{legData[i].cargoPalletsDropoff[n].signOff === undefined || legData[i].cargoPalletsDropoff[n].signOff === null || legData[i].cargoPalletsDropoff[n].signOff.firstName === undefined || legData[i].cargoPalletsDropoff[n].signOff.lastName === null ? <span className={'cargo-signoff-hint'}>Sign-off needed to confirm pallet drop-off</span> : <span className={'cargo-signoff-hint'}> </span>}
				<Button disabled={palletDropoffFieldsFirst[i] !== undefined && palletDropoffFieldsLast[i] !== undefined && palletDropoffFieldsFirst[i][n] !== undefined && palletDropoffFieldsLast[i][n] !== undefined && palletDropoffFieldsFirst[i][n] !== '' && palletDropoffFieldsLast[i][n] !== '' ? false : true} className={'btn button btn-primary btn-color-green'} id={'palletDropoff'} onClick={handleClick} title={'Click to mark pallet drop-off as complete'}>
					Confirm Pallet {n + 1} Drop-off <i className={'far fa-check'}></i>
				</Button>
			</div>
		);
	};

	const PalletPickupButtonContainer = (i, n) => {
		const handleClick = () => {
			let signOffObj = {
				firstName: palletPickupFieldsFirst[i][n],
				lastName: palletPickupFieldsLast[i][n]
			};
			legData[i].cargoPalletsPickup[n].signOff = signOffObj;
			legData[i + 1].cargoPalletsPickup[n].signOff = signOffObj;
			setLegData(legData);
			checkMissionStatus();
			legData[i].palletCompletedPickupCheck = checkCargoCompleted(legData[i].cargoPalletsPickup);
			submit(i + 1);
			checkLegStatistics(legData[i]);
			//set lift request being picked up to in-transit state
			if (legData[i].satisfiesLiftRequests !== undefined && legData[i].satisfiesLiftRequests !== null && legData[i].satisfiesLiftRequests.length > 0) {
				// eslint-disable-next-line
				legData[i].satisfiesLiftRequests.map((liftRequestId) => {
					ApiLiftRequestById(liftRequestId).then((res) => {
						if (res.history[0].state !== 'IN_TRANSIT') {
							ApiChangeLiftRequestState(liftRequestId, 'IN_TRANSIT');
						}
					});
				});
			}
		};

		return (
			<div>
				{legData[i].cargoPalletsPickup[n].signOff === undefined || legData[i].cargoPalletsPickup[n].signOff === null || legData[i].cargoPalletsPickup[n].signOff.firstName === undefined || legData[i].cargoPalletsPickup[n].signOff.lastName === null ? <span className={'cargo-signoff-hint'}>Sign-off needed to confirm pallet pick-up</span> : <span className={'cargo-signoff-hint'}> </span>}
				<Button disabled={palletPickupFieldsFirst[i] !== undefined && palletPickupFieldsLast[i] !== undefined && palletPickupFieldsFirst[i][n] !== undefined && palletPickupFieldsLast[i][n] !== undefined && palletPickupFieldsFirst[i][n] !== '' && palletPickupFieldsLast[i][n] !== '' ? false : true} className={'btn button btn-primary btn-color-green'} id={'palletPickup'} onClick={handleClick} title={'Click to mark pallet pick-up as complete'}>
					Confirm Pallet {n + 1} Pick-up <i className={'far fa-check'}></i>
				</Button>
			</div>
		);
	};

	const PalletPickupSubmitButtonContainer = (i) => {
		const handleClick = () => {
			legData[i].palletCompletedPickup = true;
			legData[i + 1].palletCompletedPickup = true;
			checkLegStatus(i + 1, null);
			setLegData(legData);
			checkMissionStatus();
			checkLegStatistics(legData[i]);
			setLegReload(Math.random());
			submit(i + 1);
		};

		return (
			<Button disabled={legData[i].palletCompletedPickupCheck !== undefined && legData[i].palletCompletedPickupCheck ? false : true} className={'btn button btn-primary btn-color-green'} id={'palletPickup'} onClick={handleClick} title={'Click to mark pallet pick-up as complete'}>
				Mark Pick-up as Completed<i className={'far fa-check'}></i>
			</Button>
		);
	};

	const PalletDropoffSubmitButtonContainer = (i) => {
		const handleClick = () => {
			legData[i].palletCompletedDropoff = true;
			legData[i - 1].palletCompletedDropoff = true;
			checkLegStatus(i - 1, null);
			setLegData(legData);
			checkMissionStatus();
			checkLegStatistics(legData[i]);
			setLegReload(Math.random());
			submit(i - 1);
		};

		return (
			<Button disabled={legData[i].palletCompletedDropoffCheck ? false : true} className={'btn button btn-primary btn-color-green'} id={'palletDropoff'} onClick={handleClick} title={'Click to mark pallet drop-off as complete'}>
				Mark Drop-off as Completed<i className={'far fa-check'}></i>
			</Button>
		);
	};

	const PaxPickupButtonContainer = (i, n) => {
		const handleClick = () => {
			let signOffObj = {
				firstName: paxPickupFieldsFirst[i][n],
				lastName: paxPickupFieldsLast[i][n]
			};

			legData[i].cargoPaxPickup[n].signOff = signOffObj;
			legData[i + 1].cargoPaxPickup[n].signOff = signOffObj;
			setLegData(legData);
			checkMissionStatus();
			legData[i].paxCompletedPickupCheck = checkCargoCompleted(legData[i].cargoPaxPickup);
			submit(i + 1);
			checkLegStatistics(legData[i]);

			//set lift request being picked up to in-transit state
			if (legData[i].satisfiesLiftRequests !== undefined && legData[i].satisfiesLiftRequests !== null && legData[i].satisfiesLiftRequests.length > 0) {
				// eslint-disable-next-line
				legData[i].satisfiesLiftRequests.map((liftRequestId) => {
					ApiLiftRequestById(liftRequestId).then((res) => {
						if (res.history[0].state !== 'IN_TRANSIT') {
							ApiChangeLiftRequestState(liftRequestId, 'IN_TRANSIT');
						}
					});
				});
			}
		};

		return (
			<div>
				{legData[i].cargoPaxPickup[n].signOff === undefined || legData[i].cargoPaxPickup[n].signOff === null || legData[i].cargoPaxPickup[n].signOff.firstName === undefined || legData[i].cargoPaxPickup[n].signOff.lastName === null ? <span className={'cargo-signoff-hint'}>Sign-off needed to confirm passenger pick-up</span> : <span className={'cargo-signoff-hint'}> </span>}
				<Button disabled={paxPickupFieldsFirst[i] !== undefined && paxPickupFieldsLast[i] !== undefined && paxPickupFieldsFirst[i][n] !== undefined && paxPickupFieldsLast[i][n] !== undefined && paxPickupFieldsFirst[i][n] !== '' && paxPickupFieldsLast[i][n] !== '' ? false : true} className={'btn button btn-primary btn-color-green'} id={'paxDropoff'} onClick={handleClick} title={'Click to mark pax drop-off as complete'}>
					Confirm Passenger Group {n + 1} Pick-up
					<i className={'far fa-check'}></i>
				</Button>
			</div>
		);
	};

	const PaxDropoffButtonContainer = (i, n) => {
		const handleClick = () => {
			let signOffObj = {
				firstName: paxDropoffFieldsFirst[i][n],
				lastName: paxDropoffFieldsLast[i][n]
			};
			legData[i].cargoPaxDropoff[n].signOff = signOffObj;
			legData[i - 1].cargoPaxDropoff[n].signOff = signOffObj;
			setLegData(legData);
			checkMissionStatus();
			legData[i].paxCompletedDropoffCheck = checkCargoCompleted(legData[i].cargoPaxPickup);
			submit(i - 1);

			//check if this is the final dropoff (all other pickups and dropoffs for this lift requestId in this mission are signed-off)
			//check for completed lift request
			if (legData[i - 1].satisfiesLiftRequests !== undefined && legData[i].satisfiesLiftRequests !== null && legData[i - 1].satisfiesLiftRequests.length > 0) {
				// eslint-disable-next-line
				legData[i - 1].satisfiesLiftRequests.map((liftRequestId) => {
					var liftRequestCompleted = checkLiftRequestCompleted(liftRequestId);
					if (liftRequestCompleted === true) {
						ApiChangeLiftRequestState(liftRequestId, 'SATISFIED');
					}
				});
			}
		};

		return (
			<div>
				{legData[i].cargoPaxDropoff[n].signOff === undefined || legData[i].cargoPaxDropoff[n].signOff === null || legData[i].cargoPaxDropoff[n].signOff.firstName === undefined || legData[i].cargoPaxDropoff[n].signOff.lastName === null ? <span className={'cargo-signoff-hint'}>Sign-off needed to confirm passenger drop-off</span> : <span className={'cargo-signoff-hint'}> </span>}
				<Button disabled={paxDropoffFieldsFirst[i] !== undefined && paxDropoffFieldsLast[i] !== undefined && paxDropoffFieldsFirst[i][n] !== undefined && paxDropoffFieldsLast[i][n] !== undefined && paxDropoffFieldsFirst[i][n] !== '' && paxDropoffFieldsLast[i][n] !== '' ? false : true} className={'btn button btn-primary btn-color-green'} id={'paxDropoff'} onClick={handleClick} title={'Click to mark pax drop-off as complete'}>
					Confirm Passenger Group {n + 1} Drop-off
					<i className={'far fa-check'}></i>
				</Button>
			</div>
		);
	};

	const AddPaxDropoffContainer = (i, n) => {
		const handleClick = () => {
			legData[i].cargoPaxDropoff[n].count += paxDropoffFieldsCount[i][n];
			legData[i - 1].cargoPaxDropoff = legData[i].cargoPaxDropoff;
			setLegData(legData);
			checkMissionStatus();
			submit(i - 1);
			paxDropoffFieldsCount[i][n] = 0;
		};

		return (
			<Button className={'btn button btn-primary btn-color-blue btn-add-pax'} onClick={handleClick} title={'Click to add pax'}>
				ADD PAX
			</Button>
		);
	};

	const AddPaxPickupContainer = (i, n) => {
		const handleClick = () => {
			legData[i].cargoPaxPickup[n].count += paxPickupFieldsCount[i][n];
			legData[i + 1].cargoPaxPickup = legData[i].cargoPaxPickup;
			setLegData(legData);
			checkMissionStatus();
			submit(i + 1);
		};

		return (
			<Button className={'btn button btn-primary btn-color-blue btn-add-pax'} onClick={handleClick} title={'Click to add pax'}>
				ADD PAX
			</Button>
		);
	};

	const PaxPickupSubmitButtonContainer = (i) => {
		const handleClick = () => {
			legData[i].paxCompletedPickup = true;
			legData[i + 1].paxCompletedPickup = true;
			checkLegStatus(i + 1, null);
			setLegData(legData);
			checkMissionStatus();
			checkLegStatistics(legData[i]);
			setLegReload(Math.random());
			submit(i + 1);
			//set lift request being picked up to in-transit state
			if (legData[i].satisfiesLiftRequests !== undefined && legData[i].satisfiesLiftRequests !== null && legData[i].satisfiesLiftRequests.length > 0) {
				// eslint-disable-next-line
				legData[i].satisfiesLiftRequests.map((liftRequestId) => {
					ApiLiftRequestById(liftRequestId).then((res) => {
						if (res.history[0].state !== 'IN_TRANSIT') {
							ApiChangeLiftRequestState(liftRequestId, 'IN_TRANSIT');
						}
					});
				});
			}
		};

		return (
			<Button disabled={legData[i].paxCompletedPickupCheck ? false : true} className={'btn button btn-primary btn-color-green'} id={'paxPickup'} onClick={handleClick} title={'Click to mark pax pick-up as complete'}>
				Mark Pick-up as Completed<i className={'far fa-check'}></i>
			</Button>
		);
	};

	const PaxDropoffSubmitButtonContainer = (i) => {
		const handleClick = () => {
			legData[i].paxCompletedDropoff = true;
			legData[i - 1].paxCompletedDropoff = true;
			checkLegStatus(i - 1, null);
			setLegData(legData);
			checkMissionStatus();
			checkLegStatistics(legData[i]);
			setLegReload(Math.random());
			submit(i - 1);
		};

		return (
			<Button disabled={legData[i].paxCompletedDropoffCheck ? false : true} className={'btn button btn-primary btn-color-green'} id={'paxDropoff'} onClick={handleClick} title={'Click to mark pax drop-off as complete'}>
				Mark Drop-off as Completed<i className={'far fa-check'}></i>
			</Button>
		);
	};

	const SaveNotesButtonContainer = (i, n, val) => {
		const handleClick = () => {
			let index = 0;
			let noteObj = {};
			noteObj.commenter = {};
			noteObj.commenter.id = props.keycloak.profile.id;
			noteObj.commenter.firstName = props.keycloak.profile.firstName;
			noteObj.commenter.lastName = props.keycloak.profile.lastName;
			noteObj.commenter.email = props.keycloak.profile.email;
			noteObj.commenter.phoneNumber = props.keycloak.attributes !== null && props.keycloak.attributes !== undefined ? props.keycloak.profile.attributes.userPhone[0] : null;
			noteObj.commenter.rank = props.keycloak.attributes !== null && props.keycloak.attributes !== undefined ? props.keycloak.profile.attributes.userGradeTitle[0] : null;
			noteObj.commenter.dutyTitle = props.keycloak.attributes !== null && props.keycloak.attributes !== undefined ? props.keycloak.profile.attributes.userDutyTitle[0] : null;
			noteObj.commenter.unit = props.keycloak.attributes !== null && props.keycloak.attributes !== undefined ? props.keycloak.profile.attributes.userUnit[0] : null;
			noteObj.createdOn = new Date();

			if (val === 'palletDropoff') {
				if (palletDropoffNotes[i] !== undefined && palletDropoffNotes[i] !== null && palletDropoffNotes[i][n] !== undefined && palletDropoffNotes[i][n] !== null) {
					if (legData[i].cargoPalletsDropoff[n].notes === undefined || legData[i].cargoPalletsDropoff[n].notes === null) {
						legData[i].cargoPalletsDropoff[n].notes = [];
						legData[i - 1].cargoPalletsDropoff[n].notes = [];
						noteObj.text = palletDropoffNotes[i][n];
						legData[i].cargoPalletsDropoff[n].notes.push(noteObj);
						legData[i - 1].cargoPalletsDropoff = legData[i].cargoPalletsDropoff;
					} else {
						noteObj.text = palletDropoffNotes[i][n];
						legData[i].cargoPalletsDropoff[n].notes.push(noteObj);
						legData[i - 1].cargoPalletsDropoff = legData[i].cargoPalletsDropoff;
					}
					index = i - 1;
				}
			}
			if (val === 'palletPickup') {
				if (palletPickupNotes[i] !== undefined && palletPickupNotes[i] !== null && palletPickupNotes[i][n] !== undefined && palletPickupNotes[i][n] !== null) {
					if (legData[i].cargoPalletsPickup[n].notes === undefined || legData[i].cargoPalletsPickup[n].notes === null) {
						legData[i].cargoPalletsPickup[n].notes = [];
						legData[i + 1].cargoPalletsPickup[n].notes = [];
						noteObj.text = palletPickupNotes[i][n];
						legData[i].cargoPalletsPickup[n].notes.push(noteObj);
						legData[i + 1].cargoPalletsPickup = legData[i].cargoPalletsPickup;
					} else {
						noteObj.text = palletPickupNotes[i][n];
						legData[i].cargoPalletsPickup[n].notes.push(noteObj);
						legData[i + 1].cargoPalletsPickup = legData[i].cargoPalletsPickup;
					}
					index = i + 1;
				}
			}
			if (val === 'paxDropoff') {
				if (paxDropoffNotes[i] !== undefined && paxDropoffNotes[i] !== null && paxDropoffNotes[i][n] !== undefined && paxDropoffNotes[i][n] !== null) {
					if (legData[i].cargoPaxDropoff[n].notes === undefined || legData[i].cargoPaxDropoff[n].notes === null) {
						legData[i - 1].cargoPaxDropoff[n].notes = [];
						legData[i].cargoPaxDropoff[n].notes = [];
						noteObj.text = paxDropoffNotes[i][n];
						legData[i].cargoPaxDropoff[n].notes.push(noteObj);
						legData[i - 1].cargoPaxDropoff = legData[i].cargoPaxDropoff;
					} else {
						noteObj.text = paxDropoffNotes[i][n];
						legData[i].cargoPaxDropoff[n].notes.push(noteObj);
						legData[i - 1].cargoPaxDropoff = legData[i].cargoPaxDropoff;
					}
					index = i - 1;
				}
			}
			if (val === 'paxPickup') {
				if (paxPickupNotes[i] !== undefined && paxPickupNotes[i] !== null && paxPickupNotes[i][n] !== undefined && paxPickupNotes[i][n] !== null) {
					if (legData[i].cargoPaxPickup[n].notes === undefined || legData[i].cargoPaxPickup[n].notes === null) {
						legData[i].cargoPaxPickup[n].notes = [];
						legData[i + 1].cargoPaxPickup[n].notes = [];
						noteObj.text = paxPickupNotes[i][n];
						legData[i].cargoPaxPickup[n].notes.push(noteObj);
						legData[i + 1].cargoPaxPickup = legData[i].cargoPaxPickup;
					} else {
						noteObj.text = paxPickupNotes[i][n];
						legData[i].cargoPaxPickup[n].notes.push(noteObj);
						legData[i + 1].cargoPaxPickup = legData[i].cargoPaxPickup;
					}
					index = i + 1;
				}
			}
			setLegData(legData);
			submit(index);
		};

		return (
			<Button className={'btn button btn-primary'} onClick={handleClick} title={'Click to save notes'}>
				Save Notes <i className={'far fa-check'}></i>
			</Button>
		);
	};

	const getDisruptionTypeEnum = (disruptionType) => {
		if (disruptionType === 'Delayed asset') {
			return 'DELAYED_ASSET';
		} else if (disruptionType === 'Asset unavailable') {
			return 'ASSET_UNAVAILABLE';
		} else if (disruptionType === 'Cancelled lift request') {
			return 'CANCELLED_LIFT_REQUEST';
		}
	};

	const handleMissionLegDisruptionClick = (e, leg, index) => {
		if (e.target.innerText === 'SUBMIT DISRUPTION') {
			if (leg.id === selectedLegId) {
				var transitionLeg = {};
				// if last stop then go to leg behind, otherwise go to leg forwards
				if (index === legData.length - 1) {
					transitionLeg = legData[index - 1];
				} else {
					transitionLeg = legData[index + 1];
				}

				var start = transitionLeg.departureTime;
				var end = transitionLeg.arrivalTime;
				var disruptionType = getDisruptionTypeEnum(missionDisruptionLegType);

				var legIdArray = [];
				legIdArray.push(transitionLeg.id);

				var missionIdArray = [];
				missionIdArray.push(params.missionId);

				var disruptionObj = {
					missionId: missionIdArray,
					legId: legIdArray,
					state: 'CREATED',
					type: disruptionType,
					startTime: start,
					endTime: end,
					createdBy: createdBy
				};

				ApiNewDisruption(disruptionObj);
				toast.success('Disruption Submitted Successfully!');
			}
		}
	};

	const missionDisruptionCheck = () => {
		const handleMissionDisruptionClick = (e) => {
			if (e.target.innerText === 'SUBMIT DISRUPTION') {
				var start = new Date(statsData[0].missionStartDateTime);
				var end = statsData[0].missionEndDateTime;
				var disruptionType = getDisruptionTypeEnum(missionDisruptionType);
				var missionIdArray = [];
				missionIdArray.push(params.missionId);

				var disruptionObj = {
					missionId: missionIdArray,
					state: 'CREATED',
					type: disruptionType,
					startTime: start,
					endTime: end,
					createdBy: createdBy
				};

				ApiNewDisruption(disruptionObj);
				toast.success('Disruption Submitted Successfully!');
			}
		};

		return (
			<Form
				key={legData}
				render={(formRenderProps) => (
					<FormElement>
						{showDisruptionManagement && (
							<Row className={'mission-disruption-option with-frame'}>
								<div className={'col-12'}>
									<i className="fa-solid fa-circle-exclamation txt-gray-c" aria-hidden={'true'}></i>

									<p>Is the whole mission disrupted?</p>

									<Field key={'legdisruptionOptions'} id={'legdisruptionOptions'} name={'legdisruptionOptions'} label={'Disruption type'} placeholder={'Disruption Type...'} component={FormComboBox} data={optionsDisruptions} onChange={(e) => setMissionDisruptionType(e.value)} />

									<Button
										type={'button'}
										className={'btn button btn-disruption btn-color-orange margin-right-0'}
										id={'disruption'}
										onClick={(e) => {
											handleMissionDisruptionClick(e);
										}}
										title={'Click to Submit Disruption for Entire Mission'}>
										<i className="far fa-circle-exclamation padding-right-10"></i>
										Submit Disruption
									</Button>
								</div>
							</Row>
						)}
					</FormElement>
				)}
			/>
		);
	};

	const handleToggleSwitch = (locked, legIndex, e) => {
		var unLockingLiftRequests = [];
		var lockingLiftRequests = [];

		// if unlocking leg, then set all applicable LRS to VALIDATED
		if (locked) {
			let tempUnlockIndex = legIndex;
			let tempEmptyCargoUnlockIndex = legIndex;
			// if unlocking, then unlock all legs after this one as well
			while (tempUnlockIndex < legData.length) {
				if (legData[tempUnlockIndex].satisfiesLiftRequests !== undefined && legData[tempUnlockIndex].satisfiesLiftRequests !== null && legData[tempUnlockIndex].satisfiesLiftRequests.length > 0) {
					// eslint-disable-next-line
					legData[tempUnlockIndex].satisfiesLiftRequests.map((liftRequestId) => {
						if (!unLockingLiftRequests.includes(liftRequestId)) {
							ApiLiftRequestById(liftRequestId).then((res) => {
								if (res.history[0].state === 'SCHEDULED') {
									ApiChangeLiftRequestState(liftRequestId, 'VALIDATED');
								}
							});
						}
						unLockingLiftRequests.push(liftRequestId);
					});
				}
				legData[tempUnlockIndex].state = 'SPAWNED';
				submit(tempUnlockIndex);
				//skipping every other index to only update LEG objects and not AIRPORT objects
				tempUnlockIndex += 2;
			}
			// unlock all previous legs that can't be locked independently
			while (tempEmptyCargoUnlockIndex > 0) {
				if (tempEmptyCargoUnlockIndex === legIndex) {
					legData[tempEmptyCargoUnlockIndex].state = 'SPAWNED';
					submit(tempEmptyCargoUnlockIndex);
				} else if (
					(legData[tempEmptyCargoUnlockIndex].cargoPalletsPickup === null || legData[tempEmptyCargoUnlockIndex].cargoPalletsPickup === undefined || legData[tempEmptyCargoUnlockIndex].cargoPalletsPickup.length === 0) &&
					(legData[tempEmptyCargoUnlockIndex].cargoPaxPickup === null || legData[tempEmptyCargoUnlockIndex].cargoPaxPickup === undefined || legData[tempEmptyCargoUnlockIndex].cargoPaxPickup.length === 0) &&
					(legData[tempEmptyCargoUnlockIndex].cargoPalletsOnboard === null || legData[tempEmptyCargoUnlockIndex].cargoPalletsOnboard === undefined || legData[tempEmptyCargoUnlockIndex].cargoPalletsOnboard.length === 0) &&
					(legData[tempEmptyCargoUnlockIndex].cargoPaxOnboard === null || legData[legIndex].cargoPaxOnboard === undefined || legData[tempEmptyCargoUnlockIndex].cargoPaxOnboard.length === 0)
				) {
					legData[tempEmptyCargoUnlockIndex].state = 'LOCKED';
					submit(tempEmptyCargoUnlockIndex);
				} else {
					legData[tempEmptyCargoUnlockIndex].state = 'SPAWNED';
					submit(tempEmptyCargoUnlockIndex);
				}
				tempEmptyCargoUnlockIndex -= 2;
			}
			locked = false;
		} else {
			//if locking leg, then set all applicable LRs to SCHEDULED.
			// if locking, then lock all legs before this one as well
			while (legIndex > 0) {
				legData[legIndex].state = 'LOCKED';
				if (legData[legIndex].satisfiesLiftRequests !== undefined && legData[legIndex].satisfiesLiftRequests !== null && legData[legIndex].satisfiesLiftRequests.length > 0) {
					// eslint-disable-next-line
					legData[legIndex].satisfiesLiftRequests.map((liftRequestId) => {
						if (!lockingLiftRequests.includes(liftRequestId)) {
							ApiLiftRequestById(liftRequestId).then((res) => {
								//adding extra states here if unsatisfied ever gets implemented
								if (res.history[0].state === 'VALIDATED' || res.history[0].state === 'UNSATISFIED') {
									ApiChangeLiftRequestState(liftRequestId, 'SCHEDULED');
								}
							});
						}
						lockingLiftRequests.push(liftRequestId);
					});
				}

				submit(legIndex);
				//skipping every other index to only update LEG objects and not AIRPORT objects
				legIndex -= 2;
			}
			locked = true;
		}
		setLegData(legData);
		setLegReload(Math.random());
		submit(legIndex);
	};

	const handleSelect = (event) => {
		if (event.expandedItems) {
			setExpanded(event.expandedItems);
		}
	};

	// Mission Legs List
	// Also calls setDataList to load data to dataList
	const missionLegsList = () => {
		return (
			<Form
				key={legData}
				render={(formRenderProps) => (
					<FormElement>
						<PanelBar onSelect={handleSelect} isControlled={true} keepItemsMounted={true} expanded={expanded}>
							{legData.map((rec, i) => {
								// if(process.env.REACT_APP_DEBUG === "true"){ console.log("leg data: ", rec); }

								const id = rec.id;
								const locationFrom = rec.departureAirport;
								const locationTo = rec.arrivalAirport;
								const actualArrival = rec.arrival !== null && rec.arrival !== undefined && rec.arrival.actual !== null ? new Date(stripTimeZone(rec.arrival.actual)) : null;
								const actualDeparture = rec.departure !== null && rec.departure !== undefined && rec.departure.actual !== null ? new Date(stripTimeZone(rec.departure.actual)) : null;
								const arrivalPlanned = rec.arrival !== null && rec.arrival !== undefined && rec.arrival.planned !== null ? stripTimeZone(rec.arrival.planned) : null;
								const departurePlanned = rec.departure !== null && rec.departure !== undefined && rec.departure.planned !== null ? stripTimeZone(rec.departure.planned) : null;

								const airportNameDeparture = rec.departureAirportName !== undefined ? rec.departureAirportName : null;
								const airportIcaoDeparture = rec.departureAirport !== undefined ? rec.departureAirport : null;

								const airportNameArrival = rec.arrivalAirportName;
								const airportIcaoArrival = rec.arrivalAirport;

								const paxCompletedDropoff = rec.paxCompletedDropoff;
								const paxCompletedPickup = rec.paxCompletedPickup;
								const palletCompletedDropoff = rec.palletCompletedDropoff;
								const palletCompletedPickup = rec.palletCompletedPickup;
								const status = rec.status;

								const totalPalletDropoff = rec.totalPalletDropoff;
								const totalPalletPickup = rec.totalPalletPickup;
								const totalPaxDropoff = rec.totalPaxDropoff;
								const totalPaxPickup = rec.totalPaxPickup;

								const cargoPalletsForDropoff = rec.cargoPalletsDropoff;
								const cargoPalletsForPickup = rec.cargoPalletsPickup;
								const cargoPaxForDropoff = rec.cargoPaxDropoff;
								const cargoPaxForPickup = rec.cargoPaxPickup;

								const configurationId = rec.aircraftConfigurationId;
								const reconfigurationEvent = rec.reconfigurationEvent;

								const title = rec.title;
								const transitionBar = rec.transitionBar;

								var emptyCargo = false;
								if ((rec.cargoPalletsPickup === null || rec.cargoPalletsPickup === undefined || rec.cargoPalletsPickup.length === 0) && (rec.cargoPaxPickup === null || rec.cargoPaxPickup === undefined || rec.cargoPaxPickup.length === 0) && (rec.cargoPalletsOnboard === null || rec.cargoPalletsOnboard === undefined || rec.cargoPalletsOnboard.length === 0) && (rec.cargoPaxOnboard === null || rec.cargoPaxOnboard === undefined || rec.cargoPaxOnboard.length === 0)) {
									emptyCargo = true;
								} else {
									emptyCargo = false;
								}

								const locked = rec.state === 'LOCKED' ? true : false;
								const legVal = rec.legVal !== undefined ? rec.legVal : null;
								const firstLeg = rec.firstLeg;
								const lastLeg = rec.lastLeg;

								const startTime = rec.start ? stripTimeZone(rec.start) : rec.start;
								const endTime = rec.end ? stripTimeZone(rec.end) : rec.end;

								/* Leg Header Title */
								const legTitle = (
									<span key={i} className={'leg-info-header'} data-leg-id={id}>
										<span className={'airport-icon'}>
											<i className={'fa-regular fa-tower-control'}></i>
										</span>
										<span className={'leg-header-item leg-counter'}>{''}</span>

										<span className={'leg-header-item leg-airport-range'}>
											<span title={title}>{title}</span>
										</span>

										{firstLeg ? (
											<span className={'leg-header-item leg-date-range'}>
												{departurePlanned} (UTC/Zulu) <i className={'far fa-arrow-right'}></i>
											</span>
										) : (
											''
										)}

										{lastLeg ? (
											<span className={'leg-header-item leg-date-range'}>
												<i className={'far fa-arrow-right'}></i> {arrivalPlanned} (UTC/Zulu)
											</span>
										) : (
											''
										)}

										{!firstLeg && !lastLeg ? (
											<span className={'leg-header-item leg-date-range'}>
												{arrivalPlanned} (UTC/Zulu) <i className={'far fa-arrow-right'}></i> {departurePlanned} (UTC/Zulu)
											</span>
										) : (
											''
										)}
									</span>
								);

								return transitionBar ? (
									<PanelBarItem className={'transition-bar'} key={i} title={<TransitionBarTitle status={status} emptyCargo={emptyCargo} lastLeg={lastLeg} startTime={startTime + ' (UTC/Zulu)'} endTime={endTime + ' (UTC/Zulu)'} locationTo={locationTo} locationFrom={locationFrom} airportNameArrival={airportNameArrival} airportNameDeparture={airportNameDeparture} i={i} legColorsArray={legColorsArray} locked={locked} legVal={legVal} id={id} configurationId={configurationId} buttonClickHandler={handleToggleSwitch} />} customProp={rec}></PanelBarItem>
								) : (
									<PanelBarItem key={i} title={legTitle} customProp={rec} disabled={transitionBar}>
										<div className={'missionstatus-details'}>
											{/* Leg Disruption: Start */}
											{showDisruptionManagement && !transitionBar && (
												<div className={'ms-group-content'}>
													<div className={'ms-group-content-inner'}>
														<Row className={'mission-disruption-option'}>
															<div className={'col-12'}>
																<i className="fa-solid fa-circle-exclamation txt-gray-c" aria-hidden={'true'}></i>

																<p className={'missionstatus-section-title no-actions'}>Has a disruption occurred at this leg?</p>

																<Field
																	key={'legdisruptionOptions'}
																	id={'legdisruptionOptions'}
																	name={'legdisruptionOptions'}
																	label={'Disruption type'}
																	placeholder={'Disruption Type...'}
																	component={FormComboBox}
																	data={optionsDisruptions}
																	onChange={(e) => {
																		setMissionDisruptionLegType(e.value);
																		setSelectedLegId(rec.id);
																	}}
																/>

																<Button
																	type={'button'}
																	className={'btn button btn-disruption btn-color-orange margin-right-0'}
																	id={'disruption'}
																	onClick={(e) => {
																		handleMissionLegDisruptionClick(e, rec, i);
																	}}
																	title={'Click to Submit Disruption for this Leg'}>
																	<i className="far fa-circle-exclamation padding-right-10"></i>
																	Submit Disruption
																</Button>
															</div>
														</Row>
													</div>
												</div>
											)}

											{/* Arrival: Start */}
											{!transitionBar && !firstLeg ? (
												<Accordion>
													<AccordionHeading>
														<div className="heading-box ms-group-header">
															<span className={'ms-group-bar-icon'}>
																<i className={'fas fa-plane-arrival'}></i>
															</span>
															<span className={'ms-group-title'}>{actualArrival !== null ? `Arrived at ${airportIcaoArrival}` : `Not Arrived at ${airportIcaoArrival}`}</span>
															{actualArrival !== null ? <span className={'ms-meta-completed date'}>{moment(actualArrival).format('YYYY-MM-DD hh:mm ')} (UTC/Zulu)</span> : <span className={'ms-meta-info date'}>Not Arrived</span>}
															<span className={'ms-group-status-icon'}>{actualArrival !== null ? <i className={'fas fa-circle-check'}></i> : <i className={'fas fa-circle-minus'}></i>}</span>
														</div>
													</AccordionHeading>
													<AccordionContent>
														<div className={'ms-group-content'}>
															<div className={'ms-group-content-inner'}>
																<h4 className={'missionstatus-section-title no-actions'}>Planned Arrival: {arrivalPlanned !== null ? arrivalPlanned : ''}</h4>

																{rec.arrival !== undefined && rec.arrival !== null && rec.arrival.actual !== null ? (
																	<h4 className={'missionstatus-section-title no-actions'}>Actual Arrival: {moment(actualArrival).tz('America/New_York').format('YYYY-MM-DD hh:mm')} (UTC/Zulu)</h4>
																) : (
																	<div className={'missionstatus-field-row half-width'}>
																		<Field value={new Date(arrivalFields[i]) || ''} label={'Date and Time Departed: (UTC)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} onChange={(e) => handleChange(i, e, 'arrival')} component={FormDateTimePicker} />
																	</div>
																)}
																<div className={'button-wrapper cargo-section-confirmation'}>
																	<div className={'button-wrapper'}>{ArrivalButtonContainer(i)}</div>
																</div>
															</div>
														</div>
													</AccordionContent>
												</Accordion>
											) : (
												''
											)}
											{/* Arrival: End */}

											{/* Pallets - Drop-off: Start */}
											{!transitionBar && cargoPalletsForDropoff !== undefined && cargoPalletsForDropoff !== null && cargoPalletsForDropoff.length > 0 ? (
												<Accordion>
													<AccordionHeading>
														<div className="heading-box ms-group-header">
															<span className={'ms-group-bar-icon'}>
																<i className={'fas fa-truck-ramp-box'}></i>
															</span>
															<span className={'ms-group-title'}>Cargo (Drop off)</span>
															{!palletCompletedDropoff ? <span className={'ms-meta-info pallet-dropoff'}>{totalPalletDropoff} Pallets</span> : <span className={'ms-meta-completed pallet-dropoff'}>{totalPalletDropoff} Cargo</span>}
															<span className={'ms-group-status-icon'}>{!palletCompletedDropoff ? <i className={'fas fa-circle-minus'}></i> : <i className={'fas fa-circle-check'}></i>}</span>
														</div>
													</AccordionHeading>
													<AccordionContent>
														<div className={'ms-group-content'}>
															<div className={'ms-group-content-inner'}>
																<h4 className={'missionstatus-section-title no-actions'}>{!palletCompletedDropoff ? `${totalPalletDropoff} Cargo for Drop-off` : `${totalPalletDropoff} Cargo Dropped Off`}</h4>

																{cargoPalletsForDropoff.map((cargo, n) => {
																	return (
																		<Accordion key={n}>
																			<AccordionHeading>
																				<span className={'cargo-name'}>Pallet {n + 1}</span>
																				<NavLink className={'cargo-link'} role={'button'} to={`/liftrequest/view/${cargo.liftrequest_id}`} title={`Click to View Lift Request ${cargo.liftrequest_id}`} target={'_blank'}>
																					<i className={'fas fa-up-right-from-square'}></i> LR#{cargo.liftrequest_id}
																				</NavLink>
																				<span className={'ms-group-status-icon'}>{cargo.signOff !== undefined && cargo.signOff.firstName !== null && cargo.signOff.firstName !== undefined ? <i className={'fas fa-circle-check'}></i> : <i className={'fas fa-circle-minus'}></i>}</span>
																			</AccordionHeading>
																			<AccordionContent>
																				<div className={'cargo-output-pane pallet pallet-drop-off'}>
																					<h4 className={'missionstatus-section-title no-actions'}>Pallet Information</h4>

																					<ul key={i} className={'cargo-details-list'}>
																						<li className={'cargo-details-item poc-primary'}>
																							<span className={'property'}>
																								<strong>Primary POC Information</strong>:
																							</span>
																							<span className={'value'}>{cargo.primaryPoc}</span>
																						</li>
																						<li className={'cargo-details-item poc-alternate'}>
																							<span className={'property'}>
																								<strong>Alternate POC Information</strong>:
																							</span>
																							<span className={'value'}>{cargo.alternatePoc}</span>
																						</li>
																						<li className={'cargo-details-item poc-alternate'}>
																							<span className={'property'}>
																								<strong>Pallet Count</strong>:
																							</span>
																							<span className={'value'}>{cargo.count ? cargo.count : '0'}</span>
																						</li>
																					</ul>

																					{cargo.signOff !== undefined && cargo.signOff.firstName !== '' && cargo.signOff.firstName !== null && cargo.signOff.lastName !== '' && cargo.signOff.lastName !== null && cargo.signOff.firstName !== undefined && cargo.signOff.lastName !== undefined ? (
																						<div className={'missionstatus-field-row'}>
																							<span>
																								<strong>Signed off by: </strong>: {cargo.signOff.firstName} {cargo.signOff.lastName}
																							</span>
																						</div>
																					) : (
																						<div>
																							<div className={'missionstatus-field-row half-width'}>
																								<label>Sign-off/Drop-off Confirmation by:</label>
																								<Field value={palletDropoffFieldsFirst[i] !== null && palletDropoffFieldsFirst[i] !== undefined && palletDropoffFieldsFirst[i][n] !== undefined ? palletDropoffFieldsFirst[i][n] : ''} onChange={(e) => handleChange(i, e, 'palletDropoffFirst', n)} label={'First Name: '} placeholder={'First name of person for sign-off...'} component={FormInput} />
																							</div>

																							<div className={'missionstatus-field-row half-width'}>
																								<Field value={palletDropoffFieldsLast[i] !== null && palletDropoffFieldsLast[i] !== undefined && palletDropoffFieldsLast[i][n] !== undefined ? palletDropoffFieldsLast[i][n] : ''} onChange={(e) => handleChange(i, e, 'palletDropoffLast', n)} label={'Last Name: '} placeholder={'Last name of person for sign-off...'} component={FormInput} />
																							</div>
																						</div>
																					)}

																					{cargo.notes !== null && cargo.notes !== undefined && (
																						<div>
																							<label>Note History:</label>
																						</div>
																					)}

																					{cargo.notes !== null &&
																						cargo.notes !== undefined &&
																						cargo.notes.map((note, m) => {
																							return (
																								<div className={'col-12'} key={m}>
																									<div className={'static-field-read-only static-textarea'}>{cargo.notes[m] && cargo.notes[m].text ? cargo.notes[m].text : 'no past notes'}</div>
																									<div className={'author-text'}>
																										{cargo.notes[m] && cargo.notes[m].commenter && cargo.notes[m].commenter.firstName ? cargo.notes[m].commenter.firstName + ' ' + cargo.notes[m].commenter.lastName + ', ' : ''}

																										{cargo.notes[m] && cargo.notes[m].createdOn && cargo.notes[m].createdOn
																											? new Intl.DateTimeFormat('en-US', {
																													year: 'numeric',
																													month: '2-digit',
																													day: '2-digit',
																													hour: '2-digit',
																													minute: '2-digit',
																													second: '2-digit'
																											  }).format(new Date(cargo.notes[m].createdOn))
																											: ''}
																									</div>
																								</div>
																							);
																						})}

																					<div className={'missionstatus-field-row'}>
																						<Field placeholder={'Any notes associated with pallet drop-off?'} label={'Notes: '} onChange={(e) => handleChange(i, e, 'palletDropoffNotes', n)} optional={true} component={FormTextArea} />
																					</div>

																					<div className={'button-wrapper cargo-section-confirmation margin-top-10'}>{SaveNotesButtonContainer(i, n, 'palletDropoff')}</div>

																					<div className={'button-wrapper cargo-section-confirmation margin-top-10'}>{PalletDropoffButtonContainer(i, n)}</div>
																				</div>
																			</AccordionContent>
																		</Accordion>
																	);
																})}

																<div className={'button-wrapper cargo-section-confirmation'}>
																	<p className={'confirmation-notice'}>
																		Are all cargoes dropped off?
																		<i className={'far fa-circle-info'}></i>
																	</p>
																	{PalletDropoffSubmitButtonContainer(i)}
																</div>
															</div>
														</div>
													</AccordionContent>
												</Accordion>
											) : (
												''
											)}

											{/* Pallets - Drop-off: End */}

											{/* Pallets - Pick-up: Start */}
											{!transitionBar && !lastLeg ? (
												<Accordion>
													<AccordionHeading>
														<div className="heading-box ms-group-header">
															<span className={'ms-group-bar-icon'}>
																<i className={'fas fa-truck-ramp-box'}></i>
															</span>
															<span className={'ms-group-title'}>Cargo (Pick Up)</span>
															{!palletCompletedPickup ? <span className={'ms-meta-info pallet-pickup'}>{totalPalletPickup} Pallets</span> : <span className={'ms-meta-completed pallet-pickup'}>{totalPalletPickup} Pallets</span>}

															<span className={'ms-group-status-icon'}>{!palletCompletedPickup ? <i className={'fas fa-circle-minus'}></i> : <i className={'fas fa-circle-check'}></i>}</span>
														</div>
													</AccordionHeading>

													<AccordionContent>
														<div className={'ms-group-content'}>
															<div className={'ms-group-content-inner'}>
																<h4 className={'missionstatus-section-title no-actions'}>{!palletCompletedPickup ? `${totalPalletPickup} Cargo for Pick-up` : `${totalPalletPickup} Cargo Picked Up`}</h4>

																{cargoPalletsForPickup !== undefined &&
																	cargoPalletsForPickup !== null &&
																	cargoPalletsForPickup.map((cargo, n) => {
																		return (
																			<Accordion key={n}>
																				<AccordionHeading>
																					<span className={'cargo-name'}>Pallet {n + 1}</span>
																					<NavLink className={'cargo-link'} role={'button'} to={`/liftrequest/view/${cargo.liftrequest_id}`} title={`Click to View Lift Request ${cargo.liftrequest_id}`} target={'_blank'}>
																						<i className={'fas fa-up-right-from-square'}></i> LR#{cargo.liftrequest_id}
																					</NavLink>
																					<span className={'ms-group-status-icon'}>{cargo.signOff !== null && cargo.signOff !== undefined && cargo.signOff.firstName !== null && cargo.signOff.firstName !== undefined ? <i className={'fas fa-circle-check'}></i> : <i className={'fas fa-circle-minus'}></i>}</span>
																				</AccordionHeading>
																				<AccordionContent>
																					<div className={'cargo-output-pane pallet pallet-pick-up'}>
																						<h4 className={'missionstatus-section-title no-actions'}>Pallet Information</h4>

																						<ul className={'cargo-details-list'}>
																							<li className={'cargo-details-item poc-primary'}>
																								<span className={'property'}>
																									<strong>Primary POC Information</strong>:
																								</span>
																								<span className={'value'}>{cargo.primaryPoc ? cargo.primaryPoc : 'no primary poc'}</span>
																							</li>
																							<li className={'cargo-details-item poc-alternate'}>
																								<span className={'property'}>
																									<strong>Alternate POC Information</strong>:
																								</span>
																								<span className={'value'}>{cargo.alternatePoc ? cargo.alternatePoc : 'no alternate poc'}</span>
																							</li>
																							<li className={'cargo-details-item poc-alternate'}>
																								<span className={'property'}>
																									<strong>Pallet Count</strong>:
																								</span>
																								<span className={'value'}>{cargo.count ? cargo.count : '0'}</span>
																							</li>
																						</ul>

																						{cargo.signOff !== null && cargo.signOff !== undefined && cargo.signOff.firstName !== '' && cargo.signOff.firstName !== null && cargo.signOff.firstName !== undefined && cargo.signOff.lastName !== '' && cargo.signOff.lastName !== null && cargo.signOff.lastName !== undefined ? (
																							<div className={'missionstatus-field-row'}>
																								<span>
																									<strong>Signed off by: </strong>: {cargo.signOff.firstName} {cargo.signOff.lastName}
																								</span>
																							</div>
																						) : (
																							<div>
																								<div className={'missionstatus-field-row half-width'}>
																									<label>Sign-off/Pick-up Confirmation by:</label>
																									<Field value={palletPickupFieldsFirst[i] !== null && palletPickupFieldsFirst[i] !== undefined && palletPickupFieldsFirst[i][n] !== undefined && palletPickupFieldsFirst[i][n] !== null ? palletPickupFieldsFirst[i][n] : ''} onChange={(e) => handleChange(i, e, 'palletPickupFirst', n)} label={'First Name: '} placeholder={'First name of person for sign-off...'} component={FormInput} />
																								</div>

																								<div className={'missionstatus-field-row half-width'}>
																									<Field value={palletPickupFieldsLast[i] !== null && palletPickupFieldsLast[i] !== undefined && palletPickupFieldsLast[i][n] !== undefined && palletPickupFieldsLast[i][n] !== null ? palletPickupFieldsLast[i][n] : ''} onChange={(e) => handleChange(i, e, 'palletPickupLast', n)} label={'Last Name: '} placeholder={'Last name of person for sign-off...'} component={FormInput} />
																								</div>
																							</div>
																						)}

																						{cargo.notes !== null && cargo.notes !== undefined && (
																							<div>
																								<label>Note History:</label>
																							</div>
																						)}

																						{cargo.notes !== null &&
																							cargo.notes !== undefined &&
																							cargo.notes.map((note, m) => {
																								return (
																									<div className={'col-12'} key={m}>
																										<div className={'static-field-read-only static-textarea'}>{cargo.notes[m] && cargo.notes[m].text ? cargo.notes[m].text : 'no past notes'}</div>
																										<div className={'author-text'}>
																											{cargo.notes[m] && cargo.notes[m].commenter && cargo.notes[m].commenter.firstName ? cargo.notes[m].commenter.firstName + ' ' + cargo.notes[m].commenter.lastName + ', ' : ''}

																											{cargo.notes[m] && cargo.notes[m].createdOn
																												? new Intl.DateTimeFormat('en-US', {
																														year: 'numeric',
																														month: '2-digit',
																														day: '2-digit',
																														hour: '2-digit',
																														minute: '2-digit',
																														second: '2-digit'
																												  }).format(new Date(cargo.notes[m].createdOn))
																												: ''}
																										</div>
																									</div>
																								);
																							})}

																						<div className={'missionstatus-field-row'}>
																							<Field placeholder={'Any notes associated with pallet pick-up?'} label={'Notes: '} onChange={(e) => handleChange(i, e, 'palletPickupNotes', n)} optional={true} component={FormTextArea} />
																						</div>

																						<div className={'button-wrapper cargo-section-confirmation margin-top-10'}>{SaveNotesButtonContainer(i, n, 'palletPickup')}</div>

																						<div className={'button-wrapper cargo-section-confirmation margin-top-10'}>{PalletPickupButtonContainer(i, n)}</div>
																					</div>
																				</AccordionContent>
																			</Accordion>
																		);
																	})}

																<div className={'button-wrapper cargo-section-confirmation'}>
																	<p className={'confirmation-notice'}>
																		Are all cargoes loaded?
																		<i className={'far fa-circle-info'}></i>
																	</p>
																	{PalletPickupSubmitButtonContainer(i)}
																</div>
															</div>
														</div>
													</AccordionContent>
												</Accordion>
											) : (
												''
											)}
											{/* Pallets - Pick-up: End */}

											{/* PAX - Drop-off: Start */}
											{!transitionBar && cargoPaxForDropoff !== undefined && cargoPaxForDropoff !== null && cargoPaxForDropoff.length > 0 ? (
												<Accordion>
													<AccordionHeading>
														<div className="heading-box ms-group-header">
															<span className={'ms-group-bar-icon'}>
																<i className={'fas fa-person-walking-arrow-right'}></i>
															</span>
															<span className={'ms-group-title'}>PAX (Drop Off)</span>
															{!paxCompletedDropoff ? <span className={'ms-meta-info pax-dropoff'}>{totalPaxDropoff} PAX</span> : <span className={'ms-meta-completed pax-dropoff'}>{totalPaxDropoff} PAX</span>}
															<span className={'ms-group-status-icon'}>{paxCompletedDropoff ? <i className={'fas fa-circle-check'}></i> : <i className={'fas fa-circle-minus'}></i>}</span>
														</div>
													</AccordionHeading>
													<AccordionContent>
														<div className={'ms-group-content'}>
															<div className={'ms-group-content-inner'}>
																<h4 className={'missionstatus-section-title no-actions'}>{!paxCompletedDropoff ? `${totalPaxDropoff} PAX Planned for Drop-off` : `${totalPaxDropoff} PAX Dropped Off`}</h4>

																{cargoPaxForDropoff.map((cargo, n) => {
																	return (
																		<Accordion key={n}>
																			<AccordionHeading>
																				<span className={'cargo-name'}>Pax Group {n + 1}</span>
																				<NavLink className={'cargo-link'} role={'button'} to={`/liftrequest/view/${cargo.liftrequest_id}`} title={`Click to View Lift Request ${cargo.liftrequest_id}`} target={'_blank'}>
																					<i className={'fas fa-up-right-from-square'}></i> LR#{cargo.liftrequest_id}
																				</NavLink>
																				<span className={'ms-group-status-icon'}>{cargo.signOff !== undefined && cargo.signOff.firstName !== null && cargo.signOff.firstName !== undefined ? <i className={'fas fa-circle-check'}></i> : <i className={'fas fa-circle-minus'}></i>}</span>
																			</AccordionHeading>
																			<AccordionContent>
																				<div className={'cargo-output-pane pax pax-drop-off'}>
																					<h4 className={'missionstatus-section-title no-actions'}>Pax Information</h4>

																					<h4 className={'missionstatus-section-title has-actions'}>{totalPaxPickup} PAX Planned for Drop-off</h4>

																					<ul key={i} className={'cargo-details-list'}>
																						<li className={'cargo-details-item poc-primary'}>
																							<span className={'property'}>
																								<strong>Primary POC Information</strong>:
																							</span>
																							<span className={'value'}>{cargo.primaryPoc ? cargo.primaryPoc : 'no primary poc'}</span>
																						</li>
																						<li className={'cargo-details-item poc-alternate'}>
																							<span className={'property'}>
																								<strong>Alternate POC Information</strong>:
																							</span>
																							<span className={'value'}>{cargo.alternatePoc ? cargo.alternatePoc : 'no alternate poc'}</span>
																						</li>
																						<li className={'cargo-details-item senior-traveler'}>
																							<span className={'property'}>
																								<strong>Senior Traveler</strong>:
																							</span>
																							<span className={'value'}>{cargo.seniorTraveler ? cargo.seniorTraveler : 'no senior traveler'}</span>
																						</li>
																						<li className={'cargo-details-item foreign-traveler'}>
																							<span className={'property'}>
																								<strong>Foreign Traveler</strong>:
																							</span>
																							<span className={'value'}>{cargo.foreign_traveler ? cargo.foreign_traveler : 'no foreign traveler'}</span>
																						</li>
																						<li className={'cargo-details-item vip-traveler'}>
																							<span className={'property'}>
																								<strong>VIP Traveler</strong>:
																							</span>
																							<span className={'value'}>{cargo.vipTraveler ? cargo.vipTraveler : 'no vip traveler'}</span>
																						</li>
																						<li className={'cargo-details-item vip-traveler'}>
																							<span className={'property'}>
																								<strong>Passenger Count</strong>:
																							</span>
																							<span className={'value'}>{cargo.count ? cargo.count : '0'}</span>
																						</li>
																					</ul>

																					<div className={'missionstatus-field-row '}>
																						<Field value={paxDropoffFieldsCount[i] !== undefined && paxDropoffFieldsCount[i][n] !== undefined ? paxDropoffFieldsCount[i][n] : '0'} className={'col-4'} placeholder={'0'} onChange={(e) => handleChange(i, e, 'paxDropoffCount', n)} format={'n0'} min={1} component={FormNumericTextBox} />
																					</div>

																					{AddPaxDropoffContainer(i, n)}

																					{cargo.signOff !== undefined && cargo.signOff.firstName !== '' && cargo.signOff.firstName !== null && cargo.signOff.lastName !== '' && cargo.signOff.lastName !== null && cargo.signOff.firstName !== undefined && cargo.signOff.lastName !== undefined ? (
																						<div className={'missionstatus-field-row'}>
																							<span>
																								<strong>Signed off by: </strong>: {cargo.signOff.firstName} {cargo.signOff.lastName}
																							</span>
																						</div>
																					) : (
																						<div>
																							<div className={'missionstatus-field-row half-width'}>
																								<label>Sign-off/Drop-off Confirmation by:</label>
																								<Field value={paxDropoffFieldsFirst[i] !== undefined && paxDropoffFieldsFirst[i][n] !== undefined ? paxDropoffFieldsFirst[i][n] : ''} onChange={(e) => handleChange(i, e, 'paxDropoffFirst', n)} label={'First Name: '} placeholder={'First name of person for sign-off...'} component={FormInput} />
																							</div>

																							<div className={'missionstatus-field-row half-width'}>
																								<Field value={paxDropoffFieldsLast[i] !== undefined && paxDropoffFieldsLast[i][n] !== undefined ? paxDropoffFieldsLast[i][n] : ''} onChange={(e) => handleChange(i, e, 'paxDropoffLast', n)} label={'Last Name: '} placeholder={'Last name of person for sign-off...'} component={FormInput} />
																							</div>
																						</div>
																					)}

																					{cargo.notes !== null && cargo.notes !== undefined && (
																						<div>
																							<label>Note History:</label>
																						</div>
																					)}

																					{cargo.notes !== null &&
																						cargo.notes !== undefined &&
																						cargo.notes.map((note, m) => {
																							return (
																								<div className={'col-12'} key={m}>
																									<div className={'static-field-read-only static-textarea'}>{cargo.notes[m] && cargo.notes[m].text ? cargo.notes[m].text : 'no past notes'}</div>
																									<div className={'author-text'}>
																										{cargo.notes[m] && cargo.notes[m].commenter && cargo.notes[m].commenter.firstName ? cargo.notes[m].commenter.firstName + ' ' + cargo.notes[m].commenter.lastName + ', ' : ''}

																										{cargo.notes[m] && cargo.notes[m].createdOn
																											? new Intl.DateTimeFormat('en-US', {
																													year: 'numeric',
																													month: '2-digit',
																													day: '2-digit',
																													hour: '2-digit',
																													minute: '2-digit',
																													second: '2-digit'
																											  }).format(new Date(cargo.notes[m].createdOn))
																											: ''}
																									</div>
																								</div>
																							);
																						})}

																					<div className={'missionstatus-field-row'}>
																						<Field placeholder={'Any notes associated with pax drop-off?'} label={'Notes: '} onChange={(e) => handleChange(i, e, 'paxDropoffNotes', n)} optional={true} component={FormTextArea} />
																					</div>

																					<div className={'button-wrapper cargo-section-confirmation margin-top-10'}>{SaveNotesButtonContainer(i, n, 'paxDropoff')}</div>

																					<div className={'button-wrapper cargo-section-confirmation margin-top-10'}>{PaxDropoffButtonContainer(i, n)}</div>
																				</div>
																			</AccordionContent>
																		</Accordion>
																	);
																})}

																<div className={'button-wrapper cargo-section-confirmation'}>
																	<p className={'confirmation-notice'}>
																		Are all PAX dropped off?
																		<i className={'far fa-circle-info'}></i>
																	</p>
																	{PaxDropoffSubmitButtonContainer(i)}
																</div>
															</div>
														</div>
													</AccordionContent>
												</Accordion>
											) : (
												''
											)}
											{/* PAX - Drop-off: End */}

											{/* PAX - Pick-up: Start */}
											{!transitionBar && !lastLeg ? (
												<Accordion>
													<AccordionHeading>
														<div className="heading-box ms-group-header">
															<span className={'ms-group-bar-icon'}>
																<i className={'fas fa-people-group'}></i>
															</span>
															<span className={'ms-group-title'}>PAX (Pick Up)</span>
															{!paxCompletedPickup ? <span className={'ms-meta-info pax-pickup'}>{totalPaxPickup} PAX</span> : <span className={'ms-meta-completed pax-pickup'}>{totalPaxPickup} PAX</span>}
															<span className={'ms-group-status-icon'}>{!paxCompletedPickup ? <i className={'fas fa-circle-minus'}></i> : <i className={'fas fa-circle-check'}></i>}</span>
														</div>
													</AccordionHeading>
													<AccordionContent>
														<div className={'ms-group-content'}>
															<div className={'ms-group-content-inner'}>
																{cargoPaxForPickup !== undefined &&
																	cargoPaxForPickup !== null &&
																	cargoPaxForPickup.map((cargo, n) => {
																		return (
																			<Accordion key={n}>
																				<AccordionHeading>
																					<span className={'cargo-name'}>Pax Group {n + 1}</span>
																					<NavLink className={'cargo-link'} role={'button'} to={`/liftrequest/view/${cargo.liftrequest_id}`} title={`Click to View Lift Request ${cargo.liftrequest_id}`} target={'_blank'}>
																						<i className={'fas fa-up-right-from-square'}></i> LR#{cargo.liftrequest_id}
																					</NavLink>
																					<span className={'ms-group-status-icon'}>{cargo.signOff !== null && cargo.signOff !== undefined && cargo.signOff.firstName !== null && cargo.signOff.firstName !== undefined ? <i className={'fas fa-circle-check'}></i> : <i className={'fas fa-circle-minus'}></i>}</span>
																				</AccordionHeading>
																				<AccordionContent>
																					<div className={'cargo-output-pane pax pax-drop-off'}>
																						<h4 className={'missionstatus-section-title no-actions'}>Pax Information</h4>
																						<h4 className={'missionstatus-section-title has-actions'}>
																							{totalPaxPickup} PAX Planned for Pick-up
																							<button className={'btn button btn-primary btn-color-blue btn-add-pax'} title={'Click to add a PAX'}>
																								Add PAX <i className={'fas fa-circle-plus'}></i>
																							</button>
																						</h4>

																						<ul key={i} className={'cargo-details-list'}>
																							<li className={'cargo-details-item poc-primary'}>
																								<span className={'property'}>
																									<strong>Primary POC Information</strong>:
																								</span>
																								<span className={'value'}>{cargo.primaryPoc ? cargo.primaryPoc : 'no primary poc'}</span>
																							</li>
																							<li className={'cargo-details-item poc-alternate'}>
																								<span className={'property'}>
																									<strong>Alternate POC Information</strong>:
																								</span>
																								<span className={'value'}>{cargo.alternatePoc ? cargo.alternatePoc : 'no alternate poc'}</span>
																							</li>
																							<li className={'cargo-details-item senior-traveler'}>
																								<span className={'property'}>
																									<strong>Senior Traveler</strong>:
																								</span>
																								<span className={'value'}>{cargo.seniorTraveler ? cargo.seniorTraveler : 'no senior traveler'}</span>
																							</li>
																							<li className={'cargo-details-item foreign-traveler'}>
																								<span className={'property'}>
																									<strong>Foreign Traveler</strong>:
																								</span>
																								<span className={'value'}>{cargo.foreign_traveler ? cargo.foreign_traveler : 'no foreign traveler'}</span>
																							</li>
																							<li className={'cargo-details-item vip-traveler'}>
																								<span className={'property'}>
																									<strong>VIP Traveler</strong>:
																								</span>
																								<span className={'value'}>{cargo.vipTraveler ? cargo.vipTraveler : 'no vip traveler'}</span>
																							</li>
																							<li className={'cargo-details-item vip-traveler'}>
																								<span className={'property'}>
																									<strong>Passenger Count</strong>:
																								</span>
																								<span className={'value'}>{cargo.count ? cargo.count : '0'}</span>
																							</li>
																						</ul>

																						<div className={'missionstatus-field-row '}>
																							<Field className={'col-4'} placeholder={'0'} onChange={(e) => handleChange(i, e, 'paxPickupCount', n)} format={'n0'} min={1} component={FormNumericTextBox} />
																						</div>

																						{AddPaxPickupContainer(i, n)}

																						{cargo.signOff !== null && cargo.signOff !== undefined && cargo.signOff.firstName !== '' && cargo.signOff.firstName !== null && cargo.signOff.lastName !== '' && cargo.signOff.lastName !== null && cargo.signOff.firstName !== undefined && cargo.signOff.lastName !== undefined ? (
																							<div className={'missionstatus-field-row'}>
																								<span>
																									<strong>Signed off by: </strong>: {cargo.signOff.firstName} {cargo.signOff.lastName}
																								</span>
																							</div>
																						) : (
																							<div>
																								<div className={'missionstatus-field-row half-width'}>
																									<label>Sign-off/Pick-up Confirmation by:</label>
																									<Field value={paxPickupFieldsLast[i] !== undefined && paxPickupFieldsLast[i][n] !== undefined ? paxPickupFieldsLast[i][n] : ''} onChange={(e) => handleChange(i, e, 'paxPickupFirst', n)} label={'First Name: '} placeholder={'First name of person for sign-off...'} component={FormInput} />
																								</div>

																								<div className={'missionstatus-field-row half-width'}>
																									<Field value={paxPickupFieldsLast[i] !== undefined && paxPickupFieldsLast[i][n] !== undefined ? paxPickupFieldsLast[i][n] : ''} onChange={(e) => handleChange(i, e, 'paxPickupLast', n)} label={'Last Name: '} placeholder={'Last name of person for sign-off...'} component={FormInput} />
																								</div>
																							</div>
																						)}

																						{cargo.notes !== null && cargo.notes !== undefined && (
																							<div>
																								<label>Note History:</label>
																							</div>
																						)}

																						{cargo.notes !== null &&
																							cargo.notes !== undefined &&
																							cargo.notes.map((note, m) => {
																								return (
																									<div className={'col-12'} key={m}>
																										<div className={'static-field-read-only static-textarea'}>{cargo.notes[m] && cargo.notes[m].text ? cargo.notes[m].text : 'no past notes'}</div>
																										<div className={'author-text'}>
																											{cargo.notes[m] && cargo.notes[m].commenter && cargo.notes[m].commenter.firstName ? cargo.notes[m].commenter.firstName + ' ' + cargo.notes[m].commenter.lastName + ', ' : ''}

																											{cargo.notes[m] && cargo.notes[m].createdOn
																												? new Intl.DateTimeFormat('en-US', {
																														year: 'numeric',
																														month: '2-digit',
																														day: '2-digit',
																														hour: '2-digit',
																														minute: '2-digit',
																														second: '2-digit'
																												  }).format(new Date(cargo.notes[m].createdOn))
																												: ''}
																										</div>
																									</div>
																								);
																							})}

																						<div className={'missionstatus-field-row'}>
																							<Field placeholder={'Any notes associated with pax drop-off?'} label={'Notes: '} onChange={(e) => handleChange(i, e, 'paxPickupNotes', n)} optional={true} component={FormTextArea} />
																						</div>

																						<div className={'button-wrapper cargo-section-confirmation margin-top-10'}>{SaveNotesButtonContainer(i, n, 'paxPickup')}</div>

																						<div className={'button-wrapper cargo-section-confirmation margin-top-10'}>{PaxPickupButtonContainer(i, n)}</div>
																					</div>
																				</AccordionContent>
																			</Accordion>
																		);
																	})}

																<div className={'button-wrapper cargo-section-confirmation'}>
																	<p className={'confirmation-notice'}>
																		Are all PAX picked up?
																		<i className={'far fa-circle-info'}></i>
																	</p>
																	{PaxPickupSubmitButtonContainer(i)}
																</div>
															</div>
														</div>
													</AccordionContent>
												</Accordion>
											) : (
												''
											)}
											{/* PAX - Pick-up: End */}

											{/* Take Off: Start */}
											{!transitionBar && !lastLeg ? (
												<Accordion>
													<AccordionHeading>
														<div className="heading-box ms-group-header">
															<span className={'ms-group-bar-icon'}>
																<i className={'fas fa-plane-departure'}></i>
															</span>
															<span className={'ms-group-title'}>{actualDeparture !== null ? `Departed from ${airportIcaoDeparture}` : `Not Departed from ${airportIcaoDeparture}`}</span>

															<span className={'ms-group-title recon-event'}>{reconfigurationEvent === true ? <b>*Reconfiguration Event</b> : ''}</span>

															{actualDeparture !== null ? <span className={'ms-meta-completed date'}>{moment(actualDeparture).format('YYYY-MM-DD hh:mm')} (UTC/Zulu)</span> : <span className={'ms-meta-info date'}>Not Departed</span>}

															<span className={'ms-group-status-icon'}>{actualDeparture !== null ? <i className={'fas fa-circle-check'}></i> : <i className={'fas fa-circle-minus'}></i>}</span>
														</div>
													</AccordionHeading>
													<AccordionContent>
														<div className={'ms-group-content'}>
															<div className={'ms-group-content-inner'}>
																<h4 className={'missionstatus-section-title no-actions'}>Planned Take-off: {departurePlanned !== null ? departurePlanned + ' (UTC/Zulu)' : ''}</h4>
																{rec.departure !== undefined && rec.departure !== null && rec.departure.actual !== null ? (
																	<h4 className={'missionstatus-section-title no-actions'}>Actual Take-off: {moment(actualDeparture).format('YYYY-MM-DD hh:mm')} (UTC/Zulu)</h4>
																) : (
																	<div className={'missionstatus-field-row half-width'}>
																		<Field value={new Date(departureFields[i]) || ''} label={'Date and Time Departed: (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} onChange={(e) => handleChange(i, e, 'departure')} component={FormDateTimePicker} />
																	</div>
																)}

																<div className={'button-wrapper cargo-section-confirmation'}>{DepartureButtonContainer(i)}</div>
															</div>
														</div>
													</AccordionContent>
												</Accordion>
											) : (
												''
											)}
											{/* Take Off: End */}
										</div>
									</PanelBarItem>
								);
							})}
						</PanelBar>
					</FormElement>
				)}
			/>
		);
	};

	// Mission Metrics Header
	const missionMetricsHeader = () => {
		return (
			<ul className={'missionstatus-stats-list'} key={legReload}>
				<li className={'stat-item legs'}>
					<p>
						<strong>Completed Legs</strong>
					</p>

					<h4>{statsData[0] && statsData[0].completedLegs !== undefined && statsData[0].completedLegs !== null ? statsData[0].completedLegs : ''} Legs</h4>
				</li>
				<li className={'stat-item legs last'}>
					<p>
						<strong>Remaining Legs</strong>
					</p>

					<h4>{statsData[0] && statsData[0].remainingLegs !== undefined && statsData[0].remainingLegs !== null ? statsData[0].remainingLegs : ''} Legs</h4>
				</li>

				<li className={'stat-item lifts'}>
					<p>
						<strong>Completed Lifts</strong>
					</p>

					<h4>{statsData[0] && statsData[0].completedLifts !== undefined && statsData[0].completedLifts !== null ? statsData[0].completedLifts : ''} Lifts</h4>
				</li>
				<li className={'stat-item lifts last'}>
					<p>
						<strong>Remaining Lifts</strong>
					</p>

					<h4>{statsData[0] && statsData[0].remainingLifts !== undefined && statsData[0].remainingLifts !== null ? statsData[0].remainingLifts : ''} Lifts</h4>
				</li>

				<li className={'stat-item days'}>
					<p>
						<strong>Completed Days</strong>
					</p>

					<h4>{statsData[0] && statsData[0].completedDays !== undefined && statsData[0].completedDays !== null ? statsData[0].completedDays : ''} Days</h4>
				</li>
				<li className={'stat-item days final '}>
					<p>
						<strong>Remaining Days</strong>
					</p>

					<h4>{statsData[0] && statsData[0].remainingDays !== undefined && statsData[0].remainingDays !== null ? statsData[0].remainingDays : ''} Days</h4>
				</li>

				<li className={'stat-item icon final'}>
					<Button fillMode="flat" title={'Click to View Additional Mission Statistics'} onClick={handleExpandedStats}>
						<i className={'fa-solid fa-chevron-down'}></i>
						<span className={'sr-only'}>Click to view Additional Mission Statistics</span>
					</Button>
				</li>

				{statsExpanded ? (
					<li className={'expanded-stat-item'}>
						<Row>
							<div className={'col-3'}>
								<p>
									<strong>Flight Hours Completed: </strong>12 hrs
								</p>
							</div>
							<div className={'col-3'}>
								<p>
									<strong>Flight Hours Remaining: </strong>12 hrs
								</p>
							</div>

							<div className={'col-3'}>
								<p>
									<strong>Distance Completed: </strong>12 mi
								</p>
							</div>
							<div className={'col-3'}>
								<p>
									<strong>Distance Remaining: </strong>12 mi
								</p>
							</div>

							<div className={'col-3'}>
								<p>
									<strong>Pallet Count Completed: </strong>12 pallets
								</p>
							</div>
							<div className={'col-3'}>
								<p>
									<strong>Pallet Count Remaining: </strong>12 pallets
								</p>
							</div>

							<div className={'col-3'}>
								<p>
									<strong>Cargo Weight Completed: </strong>12 lbs
								</p>
							</div>
							<div className={'col-3'}>
								<p>
									<strong>Cargo Weight Remaining: </strong>12 lbs
								</p>
							</div>

							<div className={'col-3'}>
								<p>
									<strong>Passenger Count Completed: </strong>12 pax
								</p>
							</div>
							<div className={'col-3'}>
								<p>
									<strong>Passenger Count Remaining: </strong>12 pax
								</p>
							</div>
						</Row>
					</li>
				) : (
					''
				)}
			</ul>
		);
	};

	// Mission Gantt Chart
	// Uses Data from missionLegsList()
	const missionGanttChart = () => {
		let palletColors = [];

		const columns = [
			{ type: 'string', label: 'Leg ID' },
			{ type: 'string', label: 'Leg Number' },
			{ type: 'string', label: 'Resource' },
			{ type: 'date', label: 'Start Date' },
			{ type: 'date', label: 'End Date' },
			{ type: 'number', label: 'Duration' },
			{ type: 'number', label: 'Percent Complete' },
			{ type: 'string', label: 'Dependencies' }
		];

		let rows = [];

		visualData.map((rec, i) => {
			rows.push([rec.id, `Leg ${i + 1}`, `leg-${i + 1}`, new Date(rec.start), new Date(rec.end), null, 100, null]);

			palletColors.push({
				color: rec.color,
				dark: rec.color,
				light: rec.color
			});
			return null;
		});

		const data = [columns, ...rows];

		// Dynamically Generate Gantt Chart Height
		/*
        const getHeight = () => {
            if(document.getElementsByTagName("svg")[0]) {
                document.getElementsByTagName("svg")[0].setAttribute("height", (legData.length + 1) * 22 + 28);
                //document.getElementsByTagName("svg")[1].setAttribute("height", (legData.length + 1) * 22 + 28);
            }

            return legData.length ? (legData.length + 1) * 22 + 28 : 0;
        }
        */

		// Gantt Chart Options
		let options = {
			gantt: {
				trackHeight: 30,
				barHeight: 10,
				barCornerRadius: 3,
				palette: palletColors
			}
			//height: getHeight(),
		};

		// Dynamically Set Gantt Chart Height
		//options.height = rows.length ? (rows.length + 1) * options.gantt.trackHeight + 28 : 0;
		options.height = (rows.length + 1) * options.gantt.trackHeight + 28;

		return <Chart chartType="Gantt" width="100%" height="auto" data={data} options={options} />;
	};

	return (
		<Container fluid className={`app-content ${windowView}`}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2>{missionId.length && loaded ? `Mission Schedule for ${missionName}` : 'Mission Schedules'}</h2>

							{headerFilterList()}
						</div>

						<div className={`mission-schedules ${missionList ? 'sidebar-expanded' : 'sidebar-collapsed'}`}>
							<div className={'mission-schedules-inner'}>
								{missionsNavigation()}
								{missionId.length && loaded && apiMissionData && missionLoaded ? (
									<React.Fragment>
										<div id={'schedule-legs-view'} className={'mission-schedule-output legs-view missionstatus-view mission-selected'}>
											<div className={'mission-schedule-inner'}>
												<div className={'schedule-section-header'}>
													<h4 className={'block-section-header'}>
														<b>Mission Status: </b>
														{apiMissionData !== null && apiMissionData.history !== undefined && apiMissionData.history !== null && apiMissionData.history[0] !== undefined && apiMissionData.history[0] !== null ? apiMissionData.history[0].state : ''} <b className={'padding-left-20'}>Tail Number: </b>
														{missionTailNumber}
													</h4>

													{titleBarSectionToggles()}
												</div>
												{statsView && legData.length ? (
													<div
														id={'missionstatus-stats'}
														className={'missionstatus-stats'}
														style={{
															height: `${legData.length ? 'auto' : '0000.1px'}`
														}}>
														{missionMetricsHeader()}
													</div>
												) : (
													''
												)}

												{ganttView && (
													<div
														id={'missionstatus-gantt'}
														className={'missionstatus-gantt'}
														style={{
															height: `${legData.length ? 'auto' : '0000.1px'}`
														}}>
														{missionGanttChart()}
													</div>
												)}

												{mapView && visualData.length ? (
													<div id={'missionstatus-map'} className={'missionstatus-map'}>
														<GoogleMap mapContainerStyle={mapOptions.style} center={mapOptions.center} zoom={mapOptions.zoom} options={mapOptions.options}>
															{visualData.map((rec, i) => (
																<React.Fragment key={i}>
																	<OverlayView position={{ lat: rec.lat, lng: rec.lng }} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
																		<Marker
																			position={{
																				lat: rec.lat,
																				lng: rec.lng
																			}}
																			type={'dot'}
																			name={rec.name}
																			color={rec.color}
																			lat={rec.lat}
																			lng={rec.lng}
																		/>
																	</OverlayView>

																	<Polyline
																		path={[
																			{
																				lat: rec.departureLat,
																				lng: rec.departureLng
																			},
																			{
																				lat: rec.arrivalLat,
																				lng: rec.arrivalLng
																			}
																		]}
																		options={{
																			clickable: rec.clickable,
																			draggable: rec.draggable,
																			editable: rec.editable,
																			fillColor: rec.fillColor,
																			fillOpacity: rec.fillOpacity,
																			geodesic: rec.geodesic,
																			radius: rec.geodesic,
																			strokeColor: rec.strokeColor,
																			strokeOpacity: rec.strokeOpacity,
																			strokeWeight: rec.strokeWeight,
																			visible: rec.visible,
																			zIndex: rec.zIndex
																		}}
																	/>
																</React.Fragment>
															))}
														</GoogleMap>
													</div>
												) : (
													''
												)}

												{legData.length ? (
													<div
														id={'missionstatus-legs'}
														className={'schedule-list schedule-legs missionstatus-legs'}
														style={{
															height: `${legData.length ? 'auto' : '0000.1px'}`
														}}>
														<div className={'schedule-list-inner'}>
															<Switch onChange={() => setShowDisruptionManagement(!showDisruptionManagement)} defaultChecked={false} onLabel={'Yes'} offLabel={'No'} checked={showDisruptionManagement} size={'large'} trackRounded={'large'} thumbRounded={'large'} style={{ width: '100px' }} />
															<Label className={'padding-left-15 '} style={{ display: 'inline-block', left: '150px' }}>
																Show Disruption Management Features?
															</Label>
														</div>

														<div className={'schedule-list-inner'}>{missionDisruptionCheck()}</div>
													</div>
												) : (
													<div id={'schedule-no-view'} className={'mission-schedule-output no-mission no-mission-selected'}>
														<div className={'mission-schedule-inner'}>
															<div className={'schedule-list schedule-legs'}>
																<div className={'schedule-list-inner'}>
																	<h3>Mission contains no legs</h3>
																</div>
															</div>
														</div>
													</div>
												)}

												<div
													id={'missionstatus-legs'}
													className={'schedule-list schedule-legs missionstatus-legs'}
													style={{
														height: `${legData.length ? 'auto' : '0000.1px'}`
													}}>
													<div className={'schedule-list-inner'}>{missionLegsList()}</div>
												</div>
											</div>
										</div>
									</React.Fragment>
								) : (
									<div id={'schedule-no-view'} className={'mission-schedule-output no-mission no-mission-selected'}>
										<div className={'mission-schedule-inner'}>
											<div className={'schedule-section-header'}>
												<h4 className={'block-section-header'}>Mission Status</h4>
											</div>
											<div className={'schedule-list schedule-legs'}>
												<div className={'schedule-list-inner'}>
													<h3>Please select a mission</h3>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</Panel>
					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionStatus);
